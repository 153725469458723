import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useForgotPassword } from "../Hooks/useForgotPassword";

function ForgetPassword() {
  const [formData, setFormData] = useState({
    email: "",
  });

  const { forgotPassword, error, message } = useForgotPassword();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    forgotPassword(formData.email);
  };

  return (
    <section className="bg-gray-50 dark:bg-gray-900 h-screen flex flex-col items-center justify-center">
      <img className="h-10 mb-5" src="/img/logoWhite.png" alt="logo" />
      <div className="lg:w-3/12 rounded-2xl dark:bg-content bg-center bg-cover">
        <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
          <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
            {/* //forget password text */}
            Forget Password
          </h1>
          <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
            <div>
              <label
                htmlFor="email"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Your email
              </label>
              <input
                type="email"
                name="email"
                id="email"
                value={formData.email}
                onChange={handleChange}
                className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="name@company.com"
                required
              />
            </div>

            {error && (
              <h1 className="text-white bg-red-500 p-2 rounded-md text-center">
                {error}
              </h1>
            )}
            {message && (
              <h1 className="bg-green-600 text-white p-2 rounded-md text-md text-center">
                {message}
              </h1>
            )}
            <button
              type="submit"
              className="bg-purple-500 mt-3 hover:bg-purple-700 w-full dark:bg-purple-500 text-white transition-all px-6 py-3 rounded-full dark:hover:bg-purple-600 hover:shadow-lg hover:shadow-purple-500/25"
            >
              Ok
            </button>
          </form>
        </div>
      </div>
    </section>
  );
}

export default ForgetPassword;
