import React from "react";
import Navbar from "../Components/Navbar/Navbar";
import Footer from "../Components/Navbar/Footer";
import NewscategoryTabs from "../Components/NewscategoryTabs";
import BlogcategoryTabs from "../Components/BlogComponents/BlogcategoryTabs";
import Subscribe from "../Components/BlogComponents/Subscribe";


export default function News () {
    return (
        <div >
            <Navbar />
            <NewscategoryTabs />
            <BlogcategoryTabs />
            <Subscribe />
            <Footer />
        </div>
    )
}