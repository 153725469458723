import { useState } from 'react'
import { RadioGroup } from '@headlessui/react'

const plans = [
  {
    
    description: '300',
    value: '300',
  },
  {
    
    description: '600',
    value: '600',
  },
  {
    
    description: '1000',
    value: '1000',
  },
  {
    
    description: 'Other',
    value: '0',
  },
]

export default function NewResWordCount({ setWordCount }) {
  const [selected, setSelected] = useState("300");
  const [showInput, setShowInput] = useState(false);
  const [userInputValue, setUserInputValue] = useState("1500");

  const handleWordCountSelect = (value) => {
    if (value === '0') {
        setShowInput(true);
        setWordCount("1500");
    }else {
        setWordCount(value);
        setSelected(value);
    }
  }

  const handleCustomValue = (ele) => {
    if (ele.target.value <= 3000) {
        setUserInputValue(ele.target.value);
        setWordCount(ele.target.value);
    }
    
  };

  return (
    showInput ? (
        <input
        type="number"
        name="wordCount"
        id="wordCountCustom"
        value={userInputValue}
        onChange={handleCustomValue}
        className="bg-purple-50 border border-gray-300 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-10/12 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-gray-800 dark:focus:border-gray-500"
        required
      />
    ) : (
        <div className="w-full px-4 py-2">
      <div className="mx-auto max-w-md">
        <RadioGroup value={selected} onChange={handleWordCountSelect}>
          <RadioGroup.Label className="sr-only">Server size</RadioGroup.Label>
          <div className="flex jusitfy-around space-x-2 ">
            {plans.map((plan) => (
              <RadioGroup.Option
                key={plan}
                value={plan.value}
                className={({ active, checked }) =>
                  `${
                    active
                      ? 'ring-2 ring-white ring-opacity-60 ring-offset-2 ring-offset-gray-800'
                      : ''
                  }
                  ${
                    checked ? 'bg-purple-700 bg-opacity-75 text-white font-bold' : 'bg-white'
                  }
                    relative cursor-pointer rounded-lg px-2 py-2 shadow-md`
                }
              >
                {({ active, checked }) => (
                  <>
                    <div className="flex w-full items-center justify-between">
                      <div className="flex items-center">
                        <div className="text-sm">
                          <RadioGroup.Label
                            as="p"
                            className={`font-medium  ${
                              checked ? 'text-purple-800' : 'text-gray-900'
                            }`}
                          >
                            {plan.name}
                          </RadioGroup.Label>
                          <RadioGroup.Description
                            as="span"
                            className={`inline ${
                              checked ? 'text-sky-500' : 'text-gray-800'
                            }`}
                          >
                            <span>
                              {plan.description}
                            </span>{' '}
                            
                            <span>{plan.disk}</span>
                          </RadioGroup.Description>
                        </div>
                      </div>
                      
                    </div>
                  </>
                )}
              </RadioGroup.Option>
            ))}
          </div>
        </RadioGroup>
      </div>
    </div>
    )
  );
}


