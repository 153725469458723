import { Download } from '@mui/icons-material';
import React from 'react'
import { URLS } from '../../Utils/constants';
// import { Link } from 'react-router-dom'

const DownloadHistoryItem = ({ title, abstract, url, multimedia_url } ) => {
  // const handleClick = (title, url) => {
  //   console.log('Title: ' + title);
  //   console.log('URL: ' + decodeURIComponent(url));
  // }

  // const downlaodFile = (e) => {

  //   let link  = e.target.id;
  //   link = link.replace('./public', '');
  //   link = `http://localhost:3001${link}`;
  //   fetch(link)
  //     .then(response => response.blob())
  //     .then(blob => {
  //       const url = window.URL.createObjectURL(blob);
  //       const link = document.createElement('a');
  //       link.href = url;
  //       link.setAttribute('download', 'myfile.pdf');
  //       document.body.appendChild(link);
  //       link.click();
  //       document.body.removeChild(link);
  //     })
  //     .catch(error => console.error(error));
  // };

  const url0 = decodeURIComponent(url);
  const multimedia_url0 = decodeURIComponent(multimedia_url);

  return (
    <div className=" max-w-screen-2xl flex items-center py-8 ml-3">
    <div className="max-w-screen-md ">
      <h1 className="title-font sm:text-4xl text-2xl mb-4 font-medium text-gray-900">{title}</h1>
      <p className="mb-8 leading-relaxed">{abstract}</p>
      <div className="flex ">
        <a className="text-purple-700 items-center mr-2 md:mb-2 lg:mb-0" href={`${URLS.baseUrls.backend}${url0.replace('./public', '')}`} target={'_blank'}>
          {
            `Download ${url0.includes('pdf') ? 'PDF' : 'Docx'}`
          }
          <Download />
        </a> 
      </div>
    </div>
    <div className=" flex w-full ">
      <img className="w-64 rounded-lg h-24" alt="hero" src={multimedia_url0} />
    </div>
  </div>
  )
}

export default DownloadHistoryItem;