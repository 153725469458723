import React from 'react'
import BrowserHistory from './browserHistory';
import DownloadHistory from './downloadHistory';

function UserHistory() {
    const [openTab, setOpenTab] = React.useState(1);
  
    return (
    <div className=' bg-white ml-3 rounded-lg' style={{ backgroundColor: "#d9c6b7" }}>
      <ul className="flex py-4 px-5 max-w-lg justify-around" role="tablist">
      <li>
          <a
            className={
              'font-medium dark:text-gray-300 hover:bg-gray-700 dark:hover:bg-transparent hover:text-blue-500' +
              (openTab === 1
                ? 'text-base  text-purple-800 border-purple-500 font-semibold border-b-2'
                : 'bg-gray')
            }
            onClick={(e) => {
              e.preventDefault();
              setOpenTab(1);
            }}
            data-toggle="tab"
            href="#link1"
            role="tablist"
          >
            Browser History
          </a>
        </li>
        <li>
          <a
            className={
              'font-medium text-gray-300 ml-6 hover:bg-gray-200 hover:bg-transparent hover:text-blue-500' +
              (openTab === 2
                ? 'text-base  ml-3 font-semibold border'
                : 'bg-gray')
            }
            onClick={(e) => {
              e.preventDefault();
              setOpenTab(2);
            }}
            data-toggle="tab"
            href="#link2"
            role="tablist"
          >
            Download History
          </a>
        </li>
      </ul>
      <div className='px-5'>
      <div className={openTab === 1 ? "max-w-screen-2xl px-5 " : "hidden"} id="link1">
        <BrowserHistory />
      </div>
      <div className={openTab === 2 ? "max-w-screen-2xl px-5 " : "hidden"} id="link2">
        <DownloadHistory />
      </div>
      </div>
    </div>
  )
}

export default UserHistory