import { faMicrophone, faMicrophoneSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import Navbar from "../Components/Navbar/Navbar";
// import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";
import * as ApiUtil from '../Utils/axios';
import * as allUrls from '../Utils/constants';
import { ClipLoader } from "react-spinners";
import { AllLanguages, freeTrialLanguages } from './langues';
import { getDataFromLocalStorage } from "../Utils/local-storage";
// import ISO6391 from 'iso-639-1';
// import { browserName } from 'react-device-detect';
//import WaveSurfer from 'wavesurfer.js';
//import MicRecorder from 'mic-recorder-to-mp3';
//import Waveform from "./WaveForm";
import AudioReactRecorder from 'audio-react-recorder';

const MainPage = () => {

const [currentModel, setCurrentModel] = useState('/img/AIModel2.png');
const [micOpen, setMicOpen] = useState(false);
const [talking, setTalking] = useState(false);
const [sendingToBackend, setSendingToBackend] = useState(false);
const [currentLanguage, setCurrentLanguage] = useState('english');
const [userPackage, setUserPackage] = useState('Free Trial');
// const [isChrome, setIsChrome] = useState(false);
const [showModel, setShowModel] = useState(false);

const [isBlocked, setIsBlocked] = useState(true);

const [recording, setRecording] = useState('none');

const [errorMsg, setErrorMsg] = useState('Please Login first');

const [authenticationUser, setAuthenticationUser] = useState(false);

const firtTimeLoad = () => {
    const user = getDataFromLocalStorage('user');

    if (user && user.result.packageType === 'Free Trial') {
      setErrorMsg('You are allowed to view this page. Upgrade you subscription.');
    }else if (user && user?.result?.packageType !== 'Free Trial') {
      setAuthenticationUser(true);
      setUserPackage(user?.result?.packageType);
      setShowModel(true);
      // if (!SpeechRecognition.browserSupportsSpeechRecognition()) {
      //   alert('Your browser does not support speech recognition software! Try Chrome desktop, maybe?');
      // }
    }
};
      useEffect(() => {
        firtTimeLoad();

        // if (browserName.includes('Chrome')) {
        //   setIsChrome(true);
        // }
      
      },[]);


      // useEffect(() => {
      //   navigator.mediaDevices.getUserMedia({ audio: true })
      //     .then((stream) => {
      //       setIsBlocked(false);
      //       // audioContextRef.current = new AudioContext();
      //       // const source = audioContextRef.current.createMediaStreamSource(stream);
      //       // analyserRef.current = audioContextRef.current.createAnalyser();
      //       // analyserRef.current.fftSize = 2048;
      //       // const bufferLength = analyserRef.current.frequencyBinCount;
      //       // dataArrayRef.current = new Uint8Array(bufferLength);
      //       // source.connect(analyserRef.current);
      //     })
      //     .catch(() => {
      //       setIsBlocked(true);
      //     });
      // }, []);

    //  useEffect(() => {
      //   console.log('== wavesurferRef before ===');
      //   if (wavesurferRef.current) {
      //     console.log('== wavesurferRef ===');
      //     const wavesurfer = WaveSurfer.create({
      //       container: wavesurferRef.current,
      //       waveColor: 'violet',
      //       progressColor: 'purple',
      //       height: 100,
      //     });
      //     wavesurfer.load(audioChunks.length ? URL.createObjectURL(new Blob(audioChunks, { type: 'audio/mpeg' })) : '');
      //     return () => wavesurfer.destroy();
      //   }
      // }, [audioChunks]);

// const commands = [
//   {
//     command: "*",
//     callback: (ele) => {
//       console.log('== ele ===', ele);
//     if (ele && ele != null && ele !== '') {
//       callBackMethod(ele);
//     }
//     },
//   },
 
// ];

//const { transcript, resetTranscript, listening } = useSpeechRecognition({ commands });

const getResponse = async (data) => {
  setSendingToBackend(true);
  let response;
  // if (isChrome) {
  //   response = await ApiUtil.http(allUrls.URLS.chat.chitChat, 'POST', { text: data, language: currentLanguage });
  // } else {
  //   const formData = new FormData();
  //   formData.append('audioFile', data);
  //   formData.append('language', currentLanguage);
  //   response = await ApiUtil.http(allUrls.URLS.chat.chitChat, 'POST', formData);
  // }

  const formData = new FormData();
    formData.append('audioFile', data);
    formData.append('language', currentLanguage);
    response = await ApiUtil.http(allUrls.URLS.chat.chitChat, 'POST', formData);
  
  if (!response.hasError) {
    if (response.success) {
      playAudio(response.data);
    }else {
      setSendingToBackend(false);
      // if (isChrome) {
      //   SpeechRecognition.stopListening();
      //   resetTranscript();
      // }
      alert(response.error);
    }
    
  }else {
    setSendingToBackend(false);
    // if (isChrome) {
    //   SpeechRecognition.stopListening();
    //   resetTranscript();
    // }
    alert(`${response.errorMessage}`);
  }

};

const playAudio = (url) => {
      try {
        const audio = new Audio(url);

        audio.play();
 
  audio.addEventListener('ended', () => {
    setTalking(false);
    // if (isChrome) {
    //   SpeechRecognition.stopListening();
    //   resetTranscript();
    // }
  });
  audio.addEventListener('play', () => {
   setTimeout(() => {
    setTalking(true); 
    setSendingToBackend(false);
   }, 350)
    
    // if (isChrome) {
    //   SpeechRecognition.stopListening();
    // }
  });
      } catch (error) {
        console.log('== error in playing audio ==', error);
        setTalking(false);
        setSendingToBackend(false);
      }
}

const changeModel = (e) => {
  setCurrentModel(e.target.src);
};



const callBackMethod = async (ele) => {
  setMicOpen(false);
  setRecording('none');
  // if (isChrome) {
  //   resetTranscript();
  //   SpeechRecognition.stopListening();
  // }
  
  await getResponse(ele);
};

const stopRecording = async  (e) => {
  if (e.blob.size > 44) {
    const blob = await fetch(e.url).then(res => res.blob());
    const file = new File([blob], 'audio-file.wav', { type: 'audio/wav' });
    await callBackMethod(file);
  }
    
  
}


const startListen =  async () => {
  // if (!isChrome) {
  //   if (!micOpen) {
  //     setRecording(true);
  //   }else {
  //     setRecording(false);
  //     //stopRecording();
  //   }
  // } else {
  //   if (!micOpen) {
  //     const lang = ISO6391.getCode(currentLanguage);
  //     SpeechRecognition.startListening({
  //       continuous: true,
  //       language: lang !== '' ? lang: 'en'
  //     });
  //   }else {
  //     SpeechRecognition.stopListening();
  //     resetTranscript();
  //   }
  // }



  if (isBlocked) {
    try {
       await navigator.mediaDevices.getUserMedia({ audio: true });
      setIsBlocked(false);
    } catch (error) {
      alert('Give Mic permission');
      return;
    }
  }

 
    if (!micOpen) {
      setRecording('start');
      setTimeout(() => {
        setRecording('pause');
      }, 100);
      setTimeout(() => {
        setRecording('start');
      }, 110);
    } 
    else {
      setRecording('stop');
    }
    
    setMicOpen(!micOpen);
 
};



    if (showModel) {
      return (
        <div>
        <div style={modelStyles}>
          <div style={contentStyles}>
            <button
              style={closeButtonStyles}
              onClick={() => setShowModel(false)}
              aria-label="Close model"
            >
              &times;
            </button>
            <h2 style={{ marginBottom: "1rem" }}>Instructions</h2>
            <p style={instructionsStyles}>
            Are you ready to get started with this exciting feature? Follow these simple instructions to get the most out of it!
            </p>
            <ol style={instructionsStyles}>
              <li>1.Locate the microphone button at the center of the screen.</li>
              <li>2.Click on the microphone button to initiate the browser's request for microphone permission.</li>
              <li> 3.When prompted, grant the browser permission to access your microphone. </li>
              <li>  4.Speak into the microphone and say anything you would like to communicate. </li>
              <li> 5.Once you have finished speaking, locate the "send" button at the bottom right corner of the screen. </li>
              <li> 6.Click on the "send" button to hear the response from the AI model. </li>
              <li> 7.If you would like to speak another message, you can click on the microphone button again and repeat steps 4-6. </li>
            </ol>
            {/* <p> For better experience use Google Chrome. </p> */}
            <p> We hope you enjoy using this exciting feature! </p>
            <div style={closeButtonContainerStyles}>
              <button className="text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 sm:mr-2 lg:mr-0 dark:bg-purple-600 dark:hover:bg-purple-700 focus:outline-none dark:focus:ring-purple-800"
              onClick={() => setShowModel(false)}
              >Close</button>
            </div>
          </div>
        </div>
      </div>
        
      );
    }else {
      return (
        <>
         <Navbar/>
           {
             authenticationUser ? (
               <div style={styles.container}>
             
               {
                 talking ? (
                   <img
                 src={currentModel.includes('.png') ? currentModel.replace('.png', '.gif'): currentModel.replace('.jpg', '.gif')}
                 style={styles.bigImage}
                 alt="Ai Model"
               />
                 ): (
                   <img
                 src={currentModel}
                 style={styles.bigImage}
                 alt="Ai Model"
               />
                 )
               }
               <div style={styles.micSection}>
               <FontAwesomeIcon
                   icon={micOpen ? faMicrophone : faMicrophoneSlash}
                   size="2xl"
                   className="w-full mt-4"
                   onClick={startListen}
                 />

                
                 <div className='speak'>
                 {
                  !sendingToBackend  && <div>
                  <AudioReactRecorder canvasWidth={450} canvasHeight={60}  state={recording}  onStop={stopRecording}  />
                  
                </div>
                 }

                    <button className="text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 sm:mr-2 lg:mr-0 dark:bg-purple-600 dark:hover:bg-purple-700 focus:outline-none dark:focus:ring-purple-800"
                    onClick={() => { setRecording('stop'); setMicOpen(false); }}
                    >Stop</button>

                 
                  
                  {/* {listening && transcript} */}
                  {/* { audioContext && <Waveform audio={audioContext}/> } */}
                  
                  {/* { recording &&  } */}
                  {
                   sendingToBackend && <ClipLoader color="#ff0000" size={80}  />
                  }
                 
                </div>
                
               </div>
               <div className="text-xl font-bold text-purple-500">
                 <h1>Choose Your Personal AI Avatar</h1>
                 <h4 className='mt-2 sm:text-sm'>Choose Language</h4>
                     <select onChange={(ele) => {setCurrentLanguage(ele.target.value)}} className="mr-3 text-sm  bg-transparent border-2 text-black outline-none cursor-pointer">
                       {
                         userPackage === 'Free Trial' ?  freeTrialLanguages.map((ele) => {
                           return <option className="text-black" value={`${ele.value}`}>{ele.name}</option>;
                         }) : AllLanguages.map((ele) => {
                           return <option className="text-black" value={`${ele.value}`}>{ele.name}</option>;
                         })
                       }
                
                 </select>
               </div>
               <div style={styles.row}>
                 <img
                   src="/img/AIModel5.png"
                   style={styles.smallImage}
                   alt="Small Ai Model"
                   onClick={changeModel}
                 />
                 <img
                   src="/img/AIModel3.jpg"
                   style={styles.smallImage}
                   alt="Small Ai Model"
                   onClick={changeModel}
                 />
                 <img
                   src="/img/AIModel11.jpg"
                   style={styles.smallImage}
                   alt="Small Ai Model"
                   onClick={changeModel}
                 />
                 <img
                   src="/img/AIModel14.png"
                   style={styles.smallImage}
                   alt="Small Ai Model"
                   onClick={changeModel}
                 />
                 <img
                   src="/img/AIModel4.png"
                   style={styles.smallImage}
                   alt="Small Ai Model"
                   onClick={changeModel}
                 />
                 <img
                   src="/img/AIModel13.png"
                   style={styles.smallImage}
                   alt="Small Ai Model"
                   onClick={changeModel}
                 />
                 <img
                   src="/img/AIModel6.png"
                   style={styles.smallImage}
                   alt="Small Ai Model"
                   onClick={changeModel}
                 />
                 <img
                   src="/img/AIModel7.png"
                   style={styles.smallImage}
                   alt="Small Ai Model"
                   onClick={changeModel}
                 />
                 <img
                   src="/img/AIModel8.jpg"
                   style={styles.smallImage}
                   alt="Small Ai Model"
                   onClick={changeModel}
                 />
                 <img
                   src="/img/AIModel9.png"
                   style={styles.smallImage}
                   alt="Small Ai Model"
                   onClick={changeModel}
                 />
                 <img
                   src="/img/AIModel10.png"
                   style={styles.smallImage}
                   alt="Small Ai Model"
                   onClick={changeModel}
                 />
                 <img
                   src="/img/AIModel12.png"
                   style={styles.smallImage}
                   alt="Small Ai Model"
                   onClick={changeModel}
                 />
               </div>
             </div>
             ): (
               <>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
       <div style={{ width: '75%', backgroundColor: '#ccc', fontWeight: 'bold', textAlign: 'center' }}>
         <span> {errorMsg}</span>
       </div>
     </div>
     
               </>
             )
           }
        </>
       );
    }

  
};



 const modelStyles = {
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 9999,
};

const contentStyles = {
  backgroundColor: "#fff",
  padding: "2rem",
  maxWidth: "90%",
  maxHeight: "90%",
  overflow: "auto",
  borderRadius: "10px",
  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.3)",
  textAlign: "left",
};

const closeButtonStyles = {
  position: "absolute",
  top: "1rem",
  right: "1rem",
  fontSize: "1.5rem",
  fontWeight: "bold",
  cursor: "pointer",
};

const instructionsStyles = {
  fontSize: "1.2rem",
  lineHeight: "1.5",
  marginBottom: "2rem",
};

const closeButtonContainerStyles = {
  display: "flex",
  justifyContent: "center",
  marginTop: "2rem",
  color: 'purple'
};

const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      maxWidth: "100vw",
      overflow: "hidden"
    },
    bigImage: {
        width: "100vw",
        maxHeight: "70vh",
        objectFit: "contain", // Adjust object-fit here
        paddingTop: '120px'
      },
    row: {
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      justifyContent: "space-between",
      width: "100%",
      padding: "1rem"
    },
    smallImage: {
      width: "14%",
      height: "auto",
      borderRadius: "50%",
      boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
      margin: "0.5rem"
    },
    micSection: {
        padding: "1rem"
      },
  };

export default MainPage;
