import React from 'react'

export default function Faq() {
  return (
    <div>
        <section className="bg-white dark:bg-gray-900">
        <div className="max-w-screen-xl px-4 pb-8 mx-auto lg:pb-24 lg:px-6 ">
            <h2 className="mb-6 text-3xl font-extrabold tracking-tight text-center text-gray-900 lg:mb-8 lg:text-3xl dark:text-white">Frequently asked questions</h2>
            <div className="max-w-screen-md mx-auto">
                <div id="accordion-flush" data-accordion="collapse" data-active-classnames="bg-white dark:bg-gray-900 text-gray-900 dark:text-white" data-inactive-classnames="text-gray-500 dark:text-gray-400">
                    <h3 id="accordion-flush-heading-1">
                        <button type="button" className="flex items-center justify-between w-full py-5 font-medium text-left text-gray-900 bg-white border-b border-gray-200 dark:border-gray-700 dark:bg-gray-900 dark:text-white" data-accordion-target="#accordion-flush-body-1" aria-expanded="true" aria-controls="accordion-flush-body-1">
                            <span>How to ensure that you get the most out of inkUrge news platform ?</span>
                            <svg data-accordion-icon="" className="w-6 h-6 rotate-180 shrink-0" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                        </button>
                    </h3>
                    <div id="accordion-flush-body-1" className="" aria-labelledby="accordion-flush-heading-1">
                        <div className="py-5 border-b border-gray-200 dark:border-gray-700">
                            <p className="mb-2 text-gray-500 dark:text-gray-400">It is indeed a great feature of inkUrge to let its users engage in most recent news bulletins. Simply Go to our News section and choose an article that interests you by clicking Engage. Next, select from a variety of options to generate a unique response using AI technology.</p>
                            <p className="text-gray-500 dark:text-gray-400">Once you have generated your response, please copy/save the title and abstract and paste/export it into the appropriate section, taking care to use your own words when summarizing the article. Visit<a href="#" className="text-purple-600 dark:text-purple-500 hover:underline"> News</a> and start exploring a plethora of news articles in multiple categories.</p>
                        </div>
                    </div>
                    <h3 id="accordion-flush-heading-1">
                        <button type="button" className="flex items-center justify-between w-full py-5 font-medium text-left text-gray-900 bg-white border-b border-gray-200 dark:border-gray-700 dark:bg-gray-900 dark:text-white" data-accordion-target="#accordion-flush-body-1" aria-expanded="true" aria-controls="accordion-flush-body-1">
                            <span>How to ensure that your work is original and avoid any potential plagiarism issues ?</span>
                            <svg data-accordion-icon="" className="w-6 h-6 rotate-180 shrink-0" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                        </button>
                    </h3>
                    <div id="accordion-flush-body-1" className="" aria-labelledby="accordion-flush-heading-1">
                        <div className="py-5 border-b border-gray-200 dark:border-gray-700">
                            <p className="mb-2 text-gray-500 dark:text-gray-400">To further protect against legal issues related to plagiarism, we strongly advise our users to adhere to ethical writing practices and to use our AI-powered tools as a starting point for their own unique content.  Next, select from a variety of options to generate a unique response using AI technology.</p>
                            
                            <p className="text-gray-500 dark:text-gray-400">We encourage users to always provide proper attribution when using information from external sources and to avoid copying content directly or paraphrasing content without proper attribution. If you have any <a href="#" className="text-purple-600 dark:text-purple-500 hover:underline">questions or concerns</a> about proper citation and attribution, please consult with your institution or seek guidance from a legal professional.</p>
                        </div>
                    </div>
                    <h3 id="accordion-flush-heading-2">
                        <button type="button" className="flex items-center justify-between w-full py-5 font-medium text-left text-gray-500 border-b border-gray-200 dark:border-gray-700 dark:text-gray-400" data-accordion-target="#accordion-flush-body-2" aria-expanded="false" aria-controls="accordion-flush-body-2">
                            <span>Can I try this platform for free?</span>
                            <svg data-accordion-icon="" className="w-6 h-6 shrink-0" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                        </button>
                    </h3>
                    <div id="accordion-flush-body-2" className="hidden" aria-labelledby="accordion-flush-heading-2">
                        <div className="py-5 border-b border-gray-200 dark:border-gray-700">
                            <p className="mb-2 text-gray-500 dark:text-gray-400">We offer a free trial of our  platform for a limited time. During the trial period, you will have access to a limited set of features and functionality, but you will not be charged.</p>
                            <p className="text-gray-500 dark:text-gray-400">Check out the <a href="#" className="text-purple-600 dark:text-purple-500 hover:underline">Free Trial</a> section to have instant access to our platform.</p>
                        </div>
                    </div>
                    <h3 id="accordion-flush-heading-2">
                        <button type="button" className="flex items-center justify-between w-full py-5 font-medium text-left text-gray-500 border-b border-gray-200 dark:border-gray-700 dark:text-gray-400" data-accordion-target="#accordion-flush-body-2" aria-expanded="false" aria-controls="accordion-flush-body-2">
                            <span>Do you offer any discounts or promotions?</span>
                            <svg data-accordion-icon="" className="w-6 h-6 shrink-0" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                        </button>
                    </h3>
                    <div id="accordion-flush-body-2" className="hidden" aria-labelledby="accordion-flush-heading-2">
                        <div className="py-5 border-b border-gray-200 dark:border-gray-700">
                            <p className="mb-2 text-gray-500 dark:text-gray-400">We may offer discounts or promotions from time to time. To stay up-to-date on the latest deals and special offers, you can sign up for the company's newsletter or follow it on social media.</p>
                            <p className="text-gray-500 dark:text-gray-400">Check out the <a href="#" className="text-purple-600 dark:text-purple-500 hover:underline">Free Trial</a> section to have instant access to our platform.</p>
                        </div>
                    </div>
                    <h3 id="accordion-flush-heading-3">
                        <button type="button" className="flex items-center justify-between w-full py-5 font-medium text-left text-gray-500 border-b border-gray-200 dark:border-gray-700 dark:text-gray-400" data-accordion-target="#accordion-flush-body-3" aria-expanded="false" aria-controls="accordion-flush-body-3">
                            <span>What are the differences between inkUrge and WriteSonic UI?</span>
                            <svg data-accordion-icon="" className="w-6 h-6 shrink-0" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                        </button>
                    </h3>
                    <div id="accordion-flush-body-3" className="hidden" aria-labelledby="accordion-flush-heading-3">
                        <div className="py-5 border-b border-gray-200 dark:border-gray-700">
                            <p className="mb-2 text-gray-500 dark:text-gray-400">The main difference is that the core components from OpenAI are sourced under the inkUrge engine, whereas WriteSonic UI is a conventional product. Another difference is that inkUrge encumbs more advanced artificial intelligence capabilities, while WriteSonic UI may be more focused on helping users generate content in a specific format or style.</p>
                            <p className="mb-2 text-gray-500 dark:text-gray-400">However, we actually recommend using both inkUrge, inkUrge Enterprise, and even Writesonic UI as there is no technical reason stopping you from using the best of two worlds.</p>
                            <p className="mb-2 text-gray-500 dark:text-gray-400">Learn more about our technologies:</p>
                            <ul className="pl-5 text-gray-500 list-disc dark:text-gray-400">
                                <li><a href="#" className="text-purple-600 dark:text-purple-500 hover:underline">inkUrge Pro</a></li>
                                <li><a href="#" className="text-purple-600 dark:text-purple-500 hover:underline">inkUrge Enterprise</a></li>
                            </ul>
                        </div>
                    </div>
                    <h3 id="accordion-flush-heading-1">
                        <button type="button" className="flex items-center justify-between w-full py-5 font-medium text-left text-gray-900 bg-white border-b border-gray-200 dark:border-gray-700 dark:bg-gray-900 dark:text-white" data-accordion-target="#accordion-flush-body-1" aria-expanded="true" aria-controls="accordion-flush-body-1">
                            <span>Can I get a refund for my subscription ?</span>
                            <svg data-accordion-icon="" className="w-6 h-6 rotate-180 shrink-0" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                        </button>
                    </h3>
                    <div id="accordion-flush-body-1" className="" aria-labelledby="accordion-flush-heading-1">
                        <div className="py-5 border-b border-gray-200 dark:border-gray-700">
                            <p className="mb-2 text-gray-500 dark:text-gray-400">We offer a 30-day money-back guarantee for all of our subscription plans. If you are not satisfied with your subscription within the first 30 days, you can request a full refund.</p>
                            <p className="text-gray-500 dark:text-gray-400">Refunds for subscriptions that have been active for longer than 30 days may be considered on a case-by-case	basis.</p>
                        </div>
                    </div>
                </div> 
            </div>               
        </div>
    </section>
    </div>
  )
}
