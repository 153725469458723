import PageLink from "./PageLink";
import { sideMenu } from "./Menu";
import {
  FaPowerOff,
} from "react-icons/fa";
import { clearDataFromLocalStorage } from "../../Utils/local-storage";

const Sidebar = ({ show_navlabel, toggleNavbar }) => 
{

  const handleLogout = () => {
    clearDataFromLocalStorage();
    window.location.href = '/';
    
  };
  return (
    <div className="hidden lg:flex flex-col p-3 px-5 py-3 mb-8 rounded-xl backdrop-blur-md">
      {show_navlabel ? (
        <div
          onClick={toggleNavbar}
          className="hover:bg-white/25 cursor-pointer  p-[18px] rounded-lg flex items-center w-full hover:no-underline"
        >
          <img src="/img/menuOpen.png" alt="" />
        </div>
      ) : (
        <img
          className="hover:bg-white/25 cursor-pointer p-[18px] rounded-lg flex items-center w-full hover:no-underline"
          src="/img/menuClose.png"
          alt=""
          onClick={toggleNavbar}
        />
      )}
      {sideMenu.map((item, key) => {
        return (
          <div className="sidebar_option py-2 "  key={key}>
            <PageLink
              show_navlabel={show_navlabel}
              to={item.to}
              title={item.label}
              Icon={item.Icon}
            />
          </div>
        );
      })}
      <PageLink title="Logout" to="/" Icon={<FaPowerOff/>} onClick={handleLogout}  show_navlabel={show_navlabel} />
    </div>
  );
};

export default Sidebar;
