import React from "react";
import { getDataFromLocalStorage } from '../../Utils/local-storage';
import BrowserHistoryItem from "./browserHistoryItem";


 function BrowserHistory () {
    const userEmail = getDataFromLocalStorage('user')?.result?.email;
const data = getDataFromLocalStorage(`savedAiResponses_news_${userEmail}`);
let cache = [];
if (data != null) {
    cache = data.data;
}

     return (
         <div className="max-w-screen-xl rounded-lg " style={{ backgroundColor: "#8b979b" }}>
             {
                 cache.map((ele) => {
                     return (
                         <BrowserHistoryItem
                             title={ele.currentTitle}
                             abstract={ele.currentAbstract}
                             url={ele.url}
                             multimedia_url={ele.multimedia_url} />
                     );
                 })
             }

         </div>
     );


}


export default BrowserHistory;