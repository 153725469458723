import React from 'react'
import { Link } from 'react-router-dom'

const SciencenewsItem3 = ({ title, abstract, url, multimedia_url } ) => {
  // const handleClick = (title, url) => {
  //   console.log('Title: ' + title);
  //   console.log('URL: ' + decodeURIComponent(url));
  // }

  const url5 = decodeURIComponent(url);
  const multimedia_url5 = decodeURIComponent(multimedia_url);

  return (
    <div className='inline-block'>
    <section className="text-gray-600 body-font">     
      <div className="py-6 flex overflow-hidden items-center ">
        <img className="w-64 rounded-lg object-center" src={multimedia_url5} alt="news" />
        <div className="px-5 justify-center items-center">
          <h2 className="tracking-widest text-xs title-font font-medium text-gray-500 mb-1">Science</h2>
          <h1 className="title-font text-lg font-medium text-gray-800 mb-3 shadow-sm">{title}</h1>
          <p className="leading-relaxed mb-3 shadow-sm">{abstract}...</p>
          <div className="flex justify-between mr-2 flex-wrap ">                  
            <a className="text-purple-700 inline-flex items-center md:mb-2 lg:mb-0" href={url5}>Read More
              <svg className="w-4 h-4 ml-2" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round">
                <path d="M5 12h14"></path>
                <path d="M12 5l7 7-7 7"></path>
              </svg>
            </a>
            <Link to={{
              pathname: `/newsres/${title}/${abstract}/${encodeURIComponent(url5)}/${encodeURIComponent(multimedia_url5)}`           
            }} target="_blank">
              <button className='w-full px-4 items-end text-lg text-indigo-600 font-bold bg-yellow-200 cursor-pointer justify-center border-2 border-purple-700 rounded'
              >Engage</button>
            </Link>
          </div>
        </div>
      </div>
    </section>
  </div>
  )
}

export default SciencenewsItem3