import React from 'react'
import HomeBanner from '../Components/HomePage/HomeBanner'
import Partners from '../Components/HomePage/Partners'
import UseCase from '../Components/HomePage/UseCase'
import Navbar from '../Components/Navbar/Navbar'
import Faq from '../Components/HomePage/Faq'
import FreeTrial from '../Components/HomePage/FreeTrial'
import Contact from '../Components/HomePage/Contact'
import Footer from '../Components/Navbar/Footer'
// import LanguageBanner from '../Components/HomePage/LanguageBanner'
import Pricing from '../Components/HomePage/Pricing'
import Features from '../Components/HomePage/Features'


export default function Home() {
  return (
    <div>
        <Navbar />
        <HomeBanner />
        <Partners />
        {/* <LanguageBanner /> */}
        <Features />
        <UseCase />
        <FreeTrial />
        <Pricing />
        <Faq />
        <Contact />
        <Footer />
    </div>
  )
}
