import React from 'react'
// import HeadlineNewsList from './BlogComponents/HeadlineNewsList';
 


export default function NewscategoryTabs ()  {
    const [openTab, setOpenTab] = React.useState(1);
  return (
    <div className=''>
        <ul>
        <li className=''>
          <a
            className={
              'font-medium hover:bg-gray-200' +
              (openTab === 1
                ? 'text-base  text-purple-800 border-purple-500 font-semibold border-b-2'
                : 'bg-white')
            }
            onClick={(e) => {
              e.preventDefault();
              setOpenTab(1);
            }}
            data-toggle="tab"
            href="#link1"
            role="tablist"
          >
            NewYork Times            
          </a>
        </li>
        </ul>
        {/* <div className={openTab === 1 ? "bg-gray-100 p-6 lg:px-10 py-10 lg:py-5 text-gray-600" : "hidden"} id="link1">
          <div>
          <HeadlineNewsList />
          </div>

      </div> */}
    </div>
  )
}

