import { useState } from "react";
import axios from "axios";

export const useForgotPassword = () => {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const [message, setMessage] = useState("");

  const forgotPassword = async (email) => {
    setIsLoading(true);
    setError(null);
    console.log(email);

    const response = await fetch("153.92.1.33:5000/user/forgotpassword", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email }),
    });

    //options
    // const options = {
    //   method: "POST",
    //   headers: { "Content-Type": "application/json" },
    //   body: JSON.stringify({ email }),
    // };

    // const response = await axios.post(
    //   "153.92.1.33:5000/user/forgotpassword",
    //   options
    // );

    const json = await response.json();

    if (!response.ok) {
      setIsLoading(false);
      setError(json.message);
    }
    if (response.ok) {
      setMessage(json.message);

      setIsLoading(false);
    }
  };

  return { forgotPassword, isLoading, error, message };
};

export default useForgotPassword;
