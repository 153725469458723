import React, { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import { setDataInLocalStorage } from '../Utils/local-storage';
import * as ApiUtil from '../Utils/axios';
import { URLS } from '../Utils/constants';

function SignIn() {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const toastId = toast.loading("Loading ...");

    const response = await ApiUtil.http(URLS.user.signIn, 'POST', formData);
    if (response.hasError) {
      toast.update(toastId, { render: `${response.errorMessage === 'Invalid credentials' ? 'Invalid credentials.\nIf your email contain dot (.) try without dot.': response.errorMessage}`, type: "error", isLoading: false,  autoClose: 3000  });
    }else {
      setDataInLocalStorage('user', response.data);
      toast.update(toastId, { render: 'Login Successfully', type: "success", isLoading: false,  autoClose: 3000  });
      window.location.href = '/dashboard';
    }

  };

  return (
    
  <section className="max-w-screen-2xl bg-gray-200 dark:bg-gray-900 flex flex-col items-start " style={{ backgroundImage: "url('/img/pexels-signup.jpg')", backgroundRepeat: "no-repeat", backgroundSize: "cover", height: "100vh", display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
    <a href="/">
    <img className="h-10 mt-6" src="/img/nrdsLogo.png" alt="logo" />
    </a>

    <div className=" lg:w-3/12 rounded-2xl bg-center bg-cover">
        <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
          <h1 className="text-xl font-bold leading-tight tracking-tight text-white md:text-2xl dark:text-white">
            Sign in to your account
          </h1>
          <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
            <div>
              <label
                htmlFor="email"
                className="block mb-2 text-sm font-medium text-white dark:text-white"
              >
                Your email
              </label>
              <input
                type="email"
                name="email"
                id="email"
                value={formData.email}
                onChange={handleChange}
                className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="name@company.com"
                required
              />
            </div>
            <div>
              <label
                htmlFor="password"
                className="block mb-2 text-sm font-medium text-white dark:text-white"
              >
                Password
              </label>
              <input
                type="password"
                name="password"
                id="password"
                value={formData.password}
                onChange={handleChange}
                placeholder="••••••••"
                className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
              />
            </div>
            <div className="flex items-center justify-between">
              <div className="flex items-start">
                <div className="flex items-center h-5">
                  <input
                    id="remember"
                    aria-describedby="remember"
                    type="checkbox"
                    className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800"
                    required
                  />
                </div>
                <div className="ml-3 text-sm">
                  <label
                    htmlFor="remember"
                    className="text-gray-500 dark:text-gray-300"
                  >
                    Remember me
                  </label>
                </div>
              </div>
              <Link
                to="/forgetpassword"
                className="text-sm text-white font-medium text-primary-600 hover:underline dark:text-primary-500"
              >
                Forgot password?
              </Link>
            </div>
            <button
              type="submit"
              className="bg-purple-500 mt-3 hover:bg-purple-700 w-full dark:bg-purple-500 text-white transition-all px-6 py-3 rounded-full border hover:shadow-lg hover:shadow-purple-500/25"
            >
              Sign in
            </button>
            <p className="text-sm font-light text-gray-500 dark:text-gray-400">
              Don’t have an account yet ?{" "}
              <Link
                to="/signup/Free Trial/Monthly"
                className="font-medium text-purple-500 hover:underline dark:text-primary-500"
              >
                Sign up
              </Link>
            </p>
          </form>
        </div>
      </div>
    </section>
  );
}

export default SignIn;
