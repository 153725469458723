import React, { useState } from "react";
import { Link } from "react-router-dom";
import { HomeIcon } from "@heroicons/react/24/solid";
import { useParams } from 'react-router-dom';
import * as ApiUtil from '../Utils/axios';
import * as allUrls from '../Utils/constants';

function SignUp() {
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    password: "",
  });

  const { packageName, type } = useParams();
  const [isError, setIsError] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const body = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      password: formData.password,
      packageName: packageName,
      packageType: type,
    };

    const response = await ApiUtil.http(allUrls.URLS.user.signUp, 'POST', body);

    if (!response.hasError) {

        if (packageName === 'Free Trial') {
          alert(response.message);
        }else {
          //console.log('=== response ==', response);
          window.location.href = response.data;
        }

    }else {
      alert(response.errorMessage);
    }
  };

  return (
    <div className="max-w-screen-2xl">
  <section className="max-w-screen-2xl bg-gray-200 dark:bg-gray-900 flex flex-col items-center justify-center" style={{ backgroundImage: "url('/img/pexels-signup.jpg')", backgroundRepeat: "no-repeat", backgroundSize: "cover", height: "100vh", display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
    <img className="h-10 mt-6" src="/img/nrdsLogo.png" alt="logo" />

    <div className=" lg:w-3/12 rounded-2xl bg-center bg-cover">
      <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
          <h1 className="text-xl font-bold leading-tight tracking-tight md:text-2xl text-white">
            Sign up for your account
          </h1>
          <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
            <div>
              <label
                htmlFor="firstname"
                className="block mb-2 text-sm font-medium text-white"
              >
                First Name
              </label>
              <input
                type="text"
                name="firstName"
                id="firstName"
                value={formData.firstName}
                onChange={handleChange}
                className="bg-gray-50 border border-gray-300 text-gray-200 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="John"
                required
              />
            </div>
            <div>
              <label
                htmlFor="lastName"
                className="block mb-2 text-sm font-medium text-white"
              >
                Last Name
              </label>
              <input
                type="text"
                name="lastName"
                id="lastName"
                value={formData.lastName}
                onChange={handleChange}
                className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Doe"
                required
              />
            </div>
            <div>
              <label
                htmlFor="email"
                className="block mb-2 text-sm font-medium text-white"
              >
                Your Email
              </label>
              <input
                type="email"
                name="email"
                id="email"
                value={formData.email}
                onChange={handleChange}
                className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="name@company.com"
                required
              />
            </div>
            <div>
              <label
                htmlFor="password"
                className="block mb-2 text-sm font-medium text-white"
              >
                Password
              </label>
              <input
                type="password"
                name="password"
                id="password"
                value={formData.password}
                onChange={handleChange}
                placeholder="••••••••"
                className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
              />
            </div>
            <div className="flex items-center justify-between">
              <div className="flex items-start">
                <div className="flex items-center h-5">
                  <input
                    id="remember"
                    aria-describedby="remember"
                    type="checkbox"
                    className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800"
                    required
                  />
                </div>

                <div className="ml-3 text-sm">
                  <label
                    htmlFor="remember"
                    className="text-gray-500 px-2"
                  >
                    Remember me
                  </label>
                </div>
              </div>
              <a
                href="#"
                className="text-sm text-white font-medium text-primary-600 hover:underline dark:text-primary-500"
              >
                Forgot password?
              </a>
            </div>
            {isError && (
              <h1 className="text-white bg-red-500 p-2 rounded-md text-center">
                {responseMessage}
              </h1>
            )}
            {!isError && responseMessage !== '' && (
              <h1 className="bg-green-600 text-white p-2 rounded-md text-md text-center">
                {responseMessage}
              </h1>
            )}
            <button
              type="submit"
              className="mt-3 hover:bg-purple-700 w-full dark:bg-purple-500 border border-purple-200 text-white transition-all px-6 py-3 rounded-full dark:hover:bg-purple-600 hover:shadow-lg hover:shadow-purple-500/25"
            >
              Sign up
            </button>
            <div className="text-sm flex items-center justify-between font-light text-gray-400 ">
              <span className="flex items-center">
                Have an account ?
                <Link
                  to="/signin"
                  className="font-medium text-purple-500 hover:underline  ml-1"
                >
                  Sign in
                </Link>
              </span>
              <Link
                to="/"
                className="font-medium text-purple-300 hover:text-purple-500 dark:text-primary-500 ml-1"
              >
                <HomeIcon className="h-5" />
              </Link>
            </div>
          </form>
        </div>
      </div>
    </section>
    </div>
  );
}

export default SignUp;
