import React from 'react'
import { Link } from 'react-router-dom'

const PoliticsnewsItem3 = ({ title, abstract, url, multimedia_url } ) => {
  // const handleClick = (title, url) => {
  //   console.log('Title: ' + title);
  //   console.log('URL: ' + decodeURIComponent(url));
  // }

  const url4 = decodeURIComponent(url);
  const multimedia_url4 = decodeURIComponent(multimedia_url);

  return (
    <div class="mx-auto px-4 sm:px-6 lg:px-8 max-w-7xl">
  <section class="text-gray-600 body-font">
    <div class="flex  items-center justify-between">
      <div class="w-full lg:w-1/2 px-4 mb-10 lg:mb-0">
        <img class="max-w-lg object-cover object-center rounded" alt="hero" src={multimedia_url4} />
      </div>
      <div class="w-full lg:w-1/2 px-3 lg:pl-24 md:pl-16 flex flex-col items-start text-left">
        <h1 class="text-3xl sm:text-4xl font-medium text-gray-900 mb-4 leading-tight">
          <span class="shadow-lg">{title}</span>
        </h1>
        <p class="mb-8 leading-relaxed text-gray-700 text-lg">
          <span class="shadow-lg">{abstract}</span>
        </p>
        <div className='flex w-full'>
        <div class="flex sm:flex-row justify-between w-full items-center">
          <a href={url4} target="_blank" class="inline-flex items-center justify-center  text-indigo-600 font-bold py-2 px-6 border-2 border-purple-700 rounded-lg shadow-lg transition-colors duration-300 ease-in-out mt-6 sm:mt-0">
            <span>Read More</span>
            <svg class="w-4 h-4 ml-2" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round">
              <path d="M5 12h14"></path>
              <path d="M12 5l7 7-7 7"></path>
            </svg>
          </a>
          <Link to={{
            pathname: `/newsres/${title}/${abstract}/${encodeURIComponent(url4)}/${encodeURIComponent(multimedia_url4)}`
          }} target="_blank">
            <button class="bg-purple-700 hover:bg-purple-800 text-white font-bold py-2 px-8 rounded-lg shadow-lg transition-colors duration-300 ease-in-out mt-6 sm:mt-0">
              Engage
            </button>
          </Link>
        </div>
        </div>
      </div>
    </div>
  </section>
</div>
  )
}

export default PoliticsnewsItem3