import {
  FaChartBar,
  FaTh,
  FaUserFriends,
  FaWallet,
  FaGrav,
  FaComments,
} from "react-icons/fa";

export const sideMenu = [
  {
    to: "/",
    label: "Home",
    Icon: <FaTh />,
  },
  {
    to: "/chat",
    label: "Chat AI",
    Icon: <FaComments />,
  },
  {
    to: "/alivii",
    label: "AliviI",
    Icon: <FaGrav />,
  },  
  {
    to: "/news",
    label: "News",
    Icon: <FaChartBar />,
  },
  {
    to: "/dashboard",
    label: "Community",
    Icon: <FaUserFriends />,
  },
];