import React, { useState } from 'react'
import { http } from '../../Utils/axios';
import { URLS } from '../../Utils/constants';

export default function Contact() {

const [name, setName] = useState('');
const [email, setEmail] = useState('');
const [message, setMessage] = useState('');

const handleChange = (e) => {
  console.log('== target ==', e.target.name);
  console.log('== value ==', e.target.value);
  if (e.target.name === 'name') {
    setName(e.target.value);
  }else if (e.target.name === 'email') {
    setEmail(e.target.value)
  }else {
    setMessage(e.target.value);
  }
};

const handleSubmit = async (e) => {
  e.preventDefault();
  if (name !== '' && email !== '' && message !== '') {
    await http(URLS.extra.contact, 'POST', { name, email, message });
      setName('');
      setEmail('');
      setMessage('');
      alert('Thank you!');
  }
}



  return (
    <div>
        <section className="text-gray-600 body-font relative" id='contact'>
  <div className="max-w-screen-xl px-4 py-2 mx-auto lg:py-12 lg:px-6">
    <div className="flex flex-col text-center mb-12">
      <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900">Contact Us</h1>
      <p className="lg:w-2/3 mx-auto leading-relaxed text-base">Our dedicated team is always here to help. Don't hesitate to reach out to us with any questions or concerns - we are here to support you in your writing journey.</p>
    </div>
    <div className="lg:w-1/2 md:w-2/3 mx-auto">
      <div className="flex flex-wrap -m-2">
        <div className="p-2 w-1/2">
          <div className="relative">
            <label for="name"  className="leading-7 text-sm text-gray-600">Name</label>
            <input type="text" onChange={handleChange} id="name" name="name" className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-500 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" required />
          </div>
        </div>
        <div className="p-2 w-1/2">
          <div className="relative">
            <label for="email" className="leading-7 text-sm text-gray-600">Email</label>
            <input type="email" onChange={handleChange} id="email" name="email" className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-500 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" required />
          </div>
        </div>
        <div className="p-2 w-full">
          <div className="relative">
            <label for="message" className="leading-7 text-sm text-gray-600">Message</label>
            <textarea onChange={handleChange} id="message" name="message" className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-500 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out" required></textarea>
          </div>
        </div>
        <div className="p-2 w-full">
          <button onClick={handleSubmit} className="text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 sm:mr-2 lg:mr-0 dark:bg-purple-600 dark:hover:bg-purple-700 focus:outline-none dark:focus:ring-purple-800">Submit</button>
        </div>
      </div>
    </div>
  </div>
</section>
    </div>
  )
}
