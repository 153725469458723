import React, { useState, useEffect } from 'react'
import axios from 'axios'
import BusinessnewsItem from './BusinessnewsItem'
import BusinessnewsItem2 from './BusinessnewsItem2'
import BusinessnewsItem3 from './BusinessnewsItem3'

export default function BusinessNewsList () {
  const [results, setArticles] = useState([])

  const getArticles = async () => {
    const response = await axios.get(`${process.env.REACT_APP_BUSNEWS_URL}?api-key=${process.env.REACT_APP_NEWS_KEY}`)
    
    setArticles(response.data.results)
  }

  useEffect(() => {
    getArticles()
  }, [])
  
  return (
    <div className='max-w-screen-2xl flex'>
  <div className="max-w-screen-lg">
  <div>
  {results.length > 0 && results.slice(0, 1).map((result) => {
    if (result.multimedia && result.abstract && !result.abstract.includes("?") && !result.title.includes("?")) {
      return (
        <BusinessnewsItem
          key={encodeURIComponent(result.url)} 
          title={result.title}
          abstract={result.abstract.slice(0, 200)}
          url={encodeURIComponent(result.url)}
          multimedia_url={encodeURIComponent(result.multimedia[0].url)}
        />
      );
    } else {
      return null;
    }
  })}
</div>
  <div className='flex'>
  {results.length > 0 && results.slice(1, 6).map((result) => {
    if (result.multimedia && result.abstract && !result.abstract.includes("?") && !result.title.includes("?")) {
      return (
        <BusinessnewsItem2
          key={encodeURIComponent(result.url)} 
          title={result.title.slice(0, 55)}
          abstract={result.abstract.slice(0, 150)}
          url={encodeURIComponent(result.url)}
          multimedia_url={encodeURIComponent(result.multimedia[0].url)}
        />
      );
    } else {
      return null;
    }
  })}
</div>
<div>
  {results.length > 0 && results.slice(6, 30).map((result) => {
    if (result.multimedia && result.abstract && !result.abstract.includes("?") && !result.title.includes("?")) {
      return (
        <BusinessnewsItem3
          key={encodeURIComponent(result.url)} 
          title={result.title}
          abstract={result.abstract.slice(0, 150)}
          url={encodeURIComponent(result.url)}
          multimedia_url={encodeURIComponent(result.multimedia[0].url)}
        />
      );
    } else {
      return null;
    }
  })}
</div>
  </div>
  <div className='w-full border rounded-lg border-gray-300 ml-6 items-center'>
    <h2 className='text-center'>Advertisments</h2>
  </div>
</div>

  )
}
