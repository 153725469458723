import React, { useState, useEffect } from 'react'
import axios from 'axios'
import HeadlineNewsItem from './HeadlineNewsItem'
import HeadlineNewsItem3 from './HeadlineNewsItem3'
import HeadlineNewsItem2 from './HeadlineNewsItem2'

const HeadlineNewsList = () => {
  const [results, setArticles] = useState([])

  const getArticles = async () => {
    const response = await axios.get(`${process.env.REACT_APP_NEWS_URL}?api-key=${process.env.REACT_APP_NEWS_KEY}`)
    
    setArticles(response.data.results)
  }

  useEffect(() => {
    getArticles()
  }, [])
  
  return (
    <div className='max-w-screen-2xl flex '>
<div className="max-w-screen-lg">
  
  <div>
  {results.length > 0 && results.slice(0, 1).map((result) => {
    if (result.multimedia && result.abstract && !result.abstract.includes("?") && !result.title.includes("?")
    && !result.abstract.includes("#") && !result.title.includes("#")) {
      return (
        <HeadlineNewsItem
          key={encodeURIComponent(result.url)} 
          title={result.title}
          abstract={result.abstract.slice(0, 200)}
          url={encodeURIComponent(result.url)}
          multimedia_url={encodeURIComponent(result.multimedia[0].url)}
        />
      );
    } else {
      return null;
    }
  })}
</div>
<div className='flex'>
    {results.length > 0 && results.slice(1, 5).map((result) => {
      if (result.multimedia && result.abstract && !result.abstract.includes("?") && !result.title.includes("?") &&
      !result.abstract.includes("#") && !result.title.includes("#")) {
        return (
          <HeadlineNewsItem2
            key={encodeURIComponent(result.url)} 
            title={result.title}
            abstract={result.abstract.slice(0, 200)}
            url={encodeURIComponent(result.url)}
            multimedia_url={encodeURIComponent(result.multimedia[0].url)}
          />
        );
      } else {
        return null;
      }
    })}
  </div>
  <div className=''>
    {results.length > 0 && results.slice(6, 30).map((result) => {
      if (result.multimedia && result.abstract && !result.abstract.includes("?") && !result.title.includes("?") &&
      !result.abstract.includes("#") && !result.title.includes("#")) {
        return (
          <HeadlineNewsItem3
            key={encodeURIComponent(result.url)} 
            title={result.title}
            abstract={result.abstract.slice(0, 200)}
            url={encodeURIComponent(result.url)}
            multimedia_url={encodeURIComponent(result.multimedia[0].url)}
          />
        );
      } else {
        return null;
      }
    })}
  </div>
  </div>
  <div className='w-full rounded-lg border border-gray-300 ml-6 max-w-screen-lg'>
    <h2 className='text-center'>Advertisments</h2>
  </div>
</div>

  )
}

export default HeadlineNewsList