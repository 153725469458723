import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
//import DefiDropdown from "./DefiDropdown";
//import DiscoverDropdown from "./DiscoverDropdown";
//import NFTDropdown from "./NFTDropdown";
// import { isMetaMaskInstalled } from "../../context/config";
//import DarkToggle from "./DarkToggle";
// import { useModal } from "../../context/ModalContext";
// import { Menu } from "@headlessui/react";
import SignIn from "./Signin";
import { FaSignInAlt, FaUser, FaUserCircle, FaHome, FaPowerOff, FaUserFriends, FaComments, FaGrav, FaChartLine, FaCogs  } from "react-icons/fa";
import { clearDataFromLocalStorage, getDataFromLocalStorage } from "../../Utils/local-storage";
import {  Navigate } from 'react-router-dom';

export default function Nav() {
  // const {
  //   walletModalHandle,
  //   metamaskModalHandle,
  //   account,
  //   isWalletAlreadyConnected,
  //   disconnectWalletFromApp,
  // } = useModal();
  const [active, setActive] = useState(false);
  const showMenu = () => {
    setActive(!active);
  };
  const user = getDataFromLocalStorage('user');
  const handleWalletConnect = async () => {
    // if (!isMetaMaskInstalled()) {
    //   metamaskModalHandle();
    // } else {
    //   walletModalHandle(); 
    // }
  };


  useEffect(() => {
   //isWalletAlreadyConnected();
  }, []);

  const substr = (str, n) => {
    return str.length > n ? str.substr(0, n - 1) : str;
  };

  return (
    <div className=" max-w-screen-2xl  ">
      <div className="justify-between flex px-5 py-3 ">
        <div className="flex">
        <Link
          to="/"
          className='font-bold text-2xl cursor-pointer flex items-center text-gray-800'
        >
                    <img src="img\nrdsLogo.png" className="w-10" alt="nrds Logo" />
        </Link>
        <h1 className="font-bold text-3xl">inkUrge</h1>
        </div>
        <div className="hidden lg:flex items-center justify-end">
          {/* <DarkToggle darkToggle={darkToggle} setDarkToggle={setDarkToggle} /> */}
          <select className="mr-3 text-sm bg-transparent text-black outline-none cursor-pointer">
            <option className=" rounded-lg" value="english">
              EN
            </option>
            {/* <option className="text-black" value="french">
              FN
            </option> */}
          </select>
          {user ? (
           <>
            <Link
              to="/dashboard"
              className=" flex items-center text-purple-500 text-lg font-bold hover:border-purple-800 transition-all dark:hover:border-purple-500 dark:hover:border-2 mx-3 py-1 dark:border-2 dark:border-primary"
            >
              <FaUserCircle />
              {user?.result && user?.result.name}
            </Link>
            <a href={`${user?.result.packageType === 'Free Trial' ? '/upgrade-pricing' :  `https://billing.stripe.com/p/login/aEUeY788R4Lr4VybII?prefilled_email=${user?.result.email}`} `} className="text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 sm:mr-2 lg:mr-0 dark:bg-purple-600 dark:hover:bg-purple-700 focus:outline-none dark:focus:ring-purple-800 ml-3">Upgrade Plan</a>
           </>
           
           
           ) : (
            <SignIn />
          )}

          {/* {account ? (
            <button
              className="border-2 flex items-center gap-2 border-white hover:border-purple-800 transition-all dark:hover:border-purple-500 dark:hover:border-2 mx-3 py-1 dark:border-2 dark:border-primary"
              onClick={() => disconnectWalletFromApp()}
            >
              <FaWallet />
              Disconnect
            </button>
          ) : (
            <button
              className="border-2 flex items-center gap-2 border-white hover:border-purple-800 transition-all dark:hover:border-purple-500 dark:hover:border-2 mx-3 py-1 dark:border-2 dark:border-primary"
              onClick={() => handleWalletConnect()}
            >
              <FaWallet />
              Connect
            </button>
          )} */}

              {/* <button
              className="border-2 flex items-center gap-2 border-white hover:border-purple-800 transition-all dark:hover:border-purple-500 dark:hover:border-2 mx-3 py-1 dark:border-2 dark:border-primary"
              onClick={() => handleWalletConnect()}
            >
              <FaWallet />
              Connect
            </button> */}

          {/* {<Wallet />} */}
        </div>
        {/* <!-- Hamburger Button --> */}

        <button
          onClick={showMenu}
          className="block text-purple-800 dark:text-purple-400 text-lg font-semibold lg:hidden focus:outline-none"
        >
          {active ? "CLOSE" : "MENU"}
        </button>
      </div>

      {/* <!-- Mobile Menu --> */}
      <div
        id="menu"
        className={
          active
            ? "relative lg:hidden p-1 mt-6 text-xl  text-black dark:text-white rounded-lg flex-auto h-screen"
            : "hidden"
        }
      >
        <div className="flex flex-col justify-center text-start mt-6">
          {user ? (
            <Link
              to="/dashboard"
              className="border flex items-center capitalize py-3"
            >
              <FaUser />
              {user && user?.result && user && user?.result.name}
            </Link>
          ) : (
            <Link
              to="/signin"
              className="border py-3 flex items-center"
            >
              <FaSignInAlt className="mr-3  text-gray-800" />
              Sign in
            </Link>
          )}
          {/* {account ? (
            <Menu as="div" className="relative w-full">
              <Menu.Button className="border border-white/25 py-4 w-full text-left">
                {substr(account.toString(), 25)}
              </Menu.Button>
              <Menu.Items className="absolute left-0 z-10 mt-2 p-3 origin-top-right rounded-md shadow-xl shadow-gray-400 dark:shadow-gray-700 bg-gray-200 dark:bg-gray-800/80 backdrop-blur-md">
                <Menu.Item>
                  {({ active }) => (
                    <button
                      className="flex items-center px-6 py-2 bg-primary cursor-pointer hover:bg-gray-800 transition-all rounded-md"
                      onClick={() => disconnectWalletFromApp()}
                    >
                      <FaWallet className="mr-3" />
                      Disconnect
                    </button>
                  )}
                </Menu.Item>
              </Menu.Items>
            </Menu>
          ) : (
            <button
              className="border border-white/25 py-4 flex items-center"
              onClick={() => handleWalletConnect()}
            >
              <FaWallet className="mr-3 text-purple-300" />
              Connect Wallet
            </button>
          )} */}
          <Link
            onClick={showMenu}
            className="border border-white/25 py-4 flex items-center"
            to="/"
          >
            <FaHome className="mr-3 text-purple-300" />
            Home
          </Link>
          <Link
            onClick={showMenu}
            className="border border-white/25 py-4 flex items-center"
            to="/chat"
          >
            <FaComments className="mr-3 text-purple-300" />
            Chat AI
          </Link>
          <Link
            onClick={showMenu}
            className="border border-white/25 py-4 flex items-center"
            to="/alivii"
          >
            <FaGrav className="mr-3 text-purple-300" />
            ALiviI
          </Link>
          <Link
            onClick={showMenu}
            className="border border-white/25 py-4 flex items-center"
            to="/news"
          >
            <FaChartLine className="mr-3 text-purple-300" />
            News
          </Link>
          <Link
            onClick={showMenu}
            className="border border-white/25 py-4 flex items-center"
            to="/community"
          >
            <FaUserFriends className="mr-3 text-purple-300" />
            Community
          </Link>
          <Link
            onClick={showMenu}
            className="border border-white/25 py-4 flex items-center"
            to="/account"
          >
            <FaCogs className="mr-3 text-purple-300" />
            Settings
          </Link>
          {/* <button
            onClick={showMenu}
            className="border border-white/25 py-4 flex items-center"
            to="/about"
          >
            Change theme
            <DarkToggle darkToggle={darkToggle} setDarkToggle={setDarkToggle} />
          </button> */}
          <Link className="border border-white/25 py-4 flex items-center" onClick={showMenu}
            to="/">
          <FaPowerOff className="mr-3 text-purple-300" />
            Logout
          </Link>
        </div>
      </div>
    </div>
  );
}
