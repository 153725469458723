import React from 'react'

export default function HomeBanner() {
  return (
    <div>
        <section className="bg-white">
        <div className="grid max-w-screen-xl px-6 pt-20 pb-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12 lg:pt-28">
            <div className="mr-auto place-self-center lg:col-span-7">
                <h1 className="max-w-2xl mb-4 text-4xl font-extrabold leading-none tracking-tight md:text-5xl xl:text-6xl dark:text-white">Advancing Life <br />through AI</h1>
                <p className="max-w-2xl mb-6 font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400">Enhance your content creation process and produce something truly exceptional. This innovative platform allows you to break Free from the limitations of conventional content and unleash your imagination.</p>
                <div className="space-y-4 sm:flex sm:space-y-0 sm:space-x-4">
                    <a href="/signup/Free Trial/Monthly" className="inline-flex items-center justify-center w-full px-5 py-3 text-sm font-medium text-center text-gray-900 border border-gray-200 rounded-lg sm:w-auto hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 dark:text-white dark:border-gray-700 dark:hover:bg-gray-700 dark:focus:ring-gray-800">
                        <img src='/img/nrdsLogo.png' className="w-4 h-4 mr-2 text-gray-500 dark:text-gray-400" xmlns="/img/nrdslogo.png" viewBox="0 0 496 512" />
                         Start Free Trial
                    </a> 
                    
                </div>
            </div>
            <div className="hidden lg:mt-0 lg:col-span-5 lg:flex">
                <img className='rounded' src="img/stefz-ideas.png" alt="hero image" />
            </div>                
        </div>
    </section>
    </div>
  )
}
