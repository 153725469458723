import React, { useEffect, useState }  from "react";
import { useParams } from 'react-router-dom';
import * as ApiUtil from '../Utils/axios';
import * as allUrls from '../Utils/constants';
import { ClipLoader } from 'react-spinners';

function PaymentHandler () {
    const { paymentStatus, sessionId } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [response, setResponse] = useState('');

    const reqBackend = async() => {
        if (paymentStatus === 'cancel') {
            setIsLoading(false);
            setResponse('Payment failed');
        }else {
          const backendResponse = await ApiUtil.http(allUrls.URLS.paymentUrl.verifyPaymentSession, 'POST', { sessionId });
          console.log('== backendResponse ==', backendResponse);
        if (!backendResponse.hasError) {
          setIsLoading(false);
          setResponse(backendResponse.data);
          
        }else {
          setIsLoading(false);
          setResponse(backendResponse.errorMessage);
        }
        }
        
    };

    useEffect(() => {reqBackend()}, []);

    return (
      <>
      { isLoading ?
        <div style={{
          'position': 'fixed',
          'top': '0',
          'left': '0',
          'width': '100%',
          'height': '100%',
          'display': 'flex',
          'justify-content': 'center',
          'align-items': 'center',
           'z-index': '50'
        }}>
          <ClipLoader color="#00ff00" size={150}  />
        </div>
        :
        <>
          <div className=""
          style={{
            'display': 'flex',
            'flex-direction': 'column',
            'justify-content': 'center',
            'align-items': 'center',
            'margin-top': '1rem',
            ' padding-left': '1rem',
            'padding-right': '1rem',
            'padding-top': '0.5rem',
            'padding-bottom': '0.5rem',
            'margin': '25%'
}}
          >
            
            <div>
            {response}
            </div>
            <button
              style={{
                'font-size': '1rem',
                'font-weight': '600',
                'padding': '0.75rem 1.5rem',
                'border-radius': '0.25rem',
                'transition': 'all 0.2s ease-in-out',
                'background-color': '#1d4ed8',
                'text-white': '#ffffff'
              }}
              onClick={() => window.location.replace('/')}
            >
              Go back home
            </button>
          </div>
        </>
      }
    </>
    
      
      
      
    );
}

export default PaymentHandler;