import React from 'react'
import { Link } from 'react-router-dom'

const HealthnewsItem2 = ({ title, abstract, url, multimedia_url } ) => {
  // const handleClick = (title, url) => {
  //   console.log('Title: ' + title);
  //   console.log('URL: ' + decodeURIComponent(url));
  // }

  const url2 = decodeURIComponent(url);
  const multimedia_url2 = decodeURIComponent(multimedia_url);

  return (
    <div className='container mx-auto'>
  <div className='grid grid-cols-8 gap-4'>
    <section className="text-gray-600  body-font col-span-8 sm:col-span-4">
      <div className="py-6 px-4">
        <div className="items-center justify-center">
          <div className="mt-4">
            <a className="relative rounded overflow-hidden">
              <img alt="ecommerce" className="w-64 sm:w-64 rounded-lg" src={multimedia_url2}/>
            </a>
            <div className="ml-3 items-center justify-between max-w-screen-xs">
              <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1">Health</h3>
              <h2 className="text-gray-900 title-font text-md font-medium">{title}</h2>
              <div className="flex ml-3 mt-4 justify-between">
                <a className="text-purple-700 inline-flex text-sm items-center md:mb-2 lg:mb-0" href={url2}>Read More
                  <svg className="w-4 h-4 ml-2" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path d="M5 12h14"></path>
                    <path d="M12 5l7 7-7 7"></path>
                  </svg>
                </a>
                <Link to={{
                  pathname: `/newsres/${title}/${abstract}/${encodeURIComponent(url2)}/${encodeURIComponent(multimedia_url2)}`
                }} target="_blank">
                  <button className='w-full px-4 items-end text-sm text-indigo-600 font-bold bg-yellow-200 cursor-pointer justify-center border-2 border-purple-700 rounded'>Engage</button>
                </Link>
              </div>
            </div>
          </div>  
        </div>
      </div>
    </section>
  </div>
</div>

  )
}

export default HealthnewsItem2