import { useState } from "react";
import axios from "axios";

export const useResetPassword = () => {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const [message, setMessage] = useState("");

  const resetPassword = async (password, id, token) => {
    setIsLoading(true);
    setError(null);

    //fetch put request
    const response = await fetch(
      `153.92.1.33:5000/user/${id}/reset/${token}`,
      {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ password }),
      }
    );
    console.log(response);

    const json = await response.json();

    if (!response.ok) {
      setIsLoading(false);
      setError(json.message);
    }
    if (response.ok) {
      setMessage(json.message);

      // update loading state
      setIsLoading(false);
    }
  };

  return { resetPassword, isLoading, error, message };
};

export default useResetPassword;
