import React, { useState, useEffect } from 'react'
import axios from 'axios'
import PoliticsnewsItem2 from './PoliticsnewsItem2'
import PoliticsnewsItem from './PoliticsnewsItem'
import PoliticsnewsItem3 from './PoliticsnewsItem3'



const PoliticsnewsList = () => {
  const [results, setArticles] = useState([])

  const getArticles = async () => {
    const response = await axios.get(`${process.env.REACT_APP_POLNEWS_URL}?api-key=${process.env.REACT_APP_NEWS_KEY}`)

    setArticles(response.data.results)
  }

  useEffect(() => {
    getArticles()
  }, [])

  return (
    <div className='flex'>
      <div className="max-w-screen-lg">

        <div className='flex'>
          {results.length > 0 && results.slice(1, 5).map((result) => {
            if (result.multimedia && result.abstract && !result.abstract.includes("?") && !result.title.includes("?")) {
              return (
                <PoliticsnewsItem2
                  key={encodeURIComponent(result.url)}
                  title={result.title}
                  abstract={result.abstract.slice(0, 200)}
                  url={encodeURIComponent(result.url)}
                  multimedia_url={encodeURIComponent(result.multimedia[0].url)}
                />
              );
            } else {
              return null;
            }
          })}
        </div>
        <div>
          {results.length > 0 && results.slice(6, 7).map((result) => {
            if (result.multimedia && result.abstract && !result.abstract.includes("?") && !result.title.includes("?")) {
              return (
                <PoliticsnewsItem3
                  key={encodeURIComponent(result.url)}
                  title={result.title}
                  abstract={result.abstract.slice(0, 150)}
                  url={encodeURIComponent(result.url)}
                  multimedia_url={encodeURIComponent(result.multimedia[0].url)}
                />
              );
            } else {
              return null;
            }
          })}
        </div>
        <div>
          {results.length > 0 && results.slice(8, 22).map((result) => {
            if (result.multimedia && result.abstract && !result.abstract.includes("?") && !result.title.includes("?")) {
              return (
                <PoliticsnewsItem
                  key={encodeURIComponent(result.url)}
                  title={result.title}
                  abstract={result.abstract.slice(0, 150)}
                  url={encodeURIComponent(result.url)}
                  multimedia_url={encodeURIComponent(result.multimedia[0].url)}
                />
              );
            } else {
              return null;
            }
          })}
        </div>
      </div>
      <div className='w-full rounded-lg border border-gray-300 ml-6 max-w-screen-lg'>
    <h2 className='text-center'>Advertisments</h2>
  </div>
    </div>

  )
}

export default PoliticsnewsList