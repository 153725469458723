import React from 'react'

export default function UseCase() {
  return (
    <div>
        <section className="flex bg-gray-50 px-4 items-center gap-8 lg:grid lg:grid-cols-2 xl:gap-16">
        <div className="max-w-screen-xl px-4 py-8 mx-auto text-center lg:py-12 lg:px-6">
            <figure className="max-w-screen-md mx-auto">
                <svg className="h-12 mx-auto mb-3 text-gray-400 dark:text-gray-600" viewBox="0 0 24 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14.017 18L14.017 10.609C14.017 4.905 17.748 1.039 23 0L23.995 2.151C21.563 3.068 20 5.789 20 8H24V18H14.017ZM0 18V10.609C0 4.905 3.748 1.038 9 0L9.996 2.151C7.563 3.068 6 5.789 6 8H9.983L9.983 18L0 18Z" fill="currentColor"/>
                </svg> 
                <blockquote>
                    <p className="text-xl font-medium text-gray-900 md:text-2xl dark:text-white">"InkUrge is a game-changer for busy news editors. With advanced features and customizable templates, it's efficient, user-friendly, and has saved me countless hours of editing and formatting. Highly recommended for anyone looking to streamline their writing process."</p>
                </blockquote>
                <figcaption className="flex items-center justify-center mt-6 space-x-3">
                    <img className="w-6 h-6 rounded-full" src="img\Olivia-Rodriguez.jpg" alt="profilepic" />
                    <div className="flex items-center divide-x-2 divide-gray-500 dark:divide-gray-700">
                        <div className="pr-3 font-medium text-gray-900 dark:text-white">Olivia Rodriguez</div>
                        <div className="pl-3 text-sm font-light text-gray-500 dark:text-gray-400">Cheif News Editor</div>
                    </div>
                </figcaption>
            </figure>
        </div>
        <div className="max-w-screen-xl px-4 py-8 mx-auto text-center lg:py-12 lg:px-6">
            <figure className="max-w-screen-md mx-auto">
                <svg className="h-12 mx-auto mb-3 text-gray-400 dark:text-gray-600" viewBox="0 0 24 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14.017 18L14.017 10.609C14.017 4.905 17.748 1.039 23 0L23.995 2.151C21.563 3.068 20 5.789 20 8H24V18H14.017ZM0 18V10.609C0 4.905 3.748 1.038 9 0L9.996 2.151C7.563 3.068 6 5.789 6 8H9.983L9.983 18L0 18Z" fill="currentColor"/>
                </svg> 
                <blockquote>
                    <p className="text-xl font-medium text-gray-900 md:text-2xl dark:text-white">"I am grateful for the free option, but even as a paying customer, I feel that the platform offers good value for money. The Standard package meets my needs for my personal blog, while the Pro and Premium packages provide advanced features to improve my writing skills."</p>
                </blockquote>
                <figcaption className="flex items-center justify-center mt-6 space-x-3">
                    <img className="w-6 h-6 rounded-full object-cover" src="img\Ethan-Yang.jpg" alt="profilepic" />
                    <div className="flex items-center divide-x-2 divide-gray-500 dark:divide-gray-700">
                        <div className="pr-3 font-medium text-gray-900 dark:text-white">Ethan Yang</div>
                        <div className="pl-3 text-sm font-light text-gray-500 dark:text-gray-400">Blogger</div>
                    </div>
                </figcaption>
            </figure>
        </div>
      </section>
    </div>
  )
}
