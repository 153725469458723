import React from 'react'
import { Link } from 'react-router-dom'

const SportsnewsItem = ({ title, abstract, url, multimedia_url } ) => {
  // const handleClick = (title, url) => {
  //   console.log('Title: ' + title);
  //   console.log('URL: ' + decodeURIComponent(url));
  // }

  const url6 = decodeURIComponent(url);
  const multimedia_url6 = decodeURIComponent(multimedia_url);

  return (
    <div className='inline-block max-w-screen-xl'>
      <section className="text-gray-600 body-font">
        <div className="container px-4 py-10 mx-auto">
          <div className="flex flex-wrap -m-4">
            <div className="p-4 max-w-screen-xl ">
              <div className="h-full border-2 border-yellow-800 border-opacity-80 rounded-lg overflow-hidden">
                <img className="lg:h-56 md:h-36 max-w-screen-xs rounded-lg object-cover object-center" src={multimedia_url6} alt="news" />
                <h2 className="tracking-widest text-xs title-font font-medium text-gray-400 mb-1">Sports</h2>
                <div className="max-w-screen-xs lg:w-1/2 px-3 lg:pl-24 md:pl-16 flex flex-col items-center text-center">
                  <h1 className="text-2xl sm:text-4xl font-medium text-gray-900 mb-4 leading-tight">
                    <span className="shadow-lg">{title}</span>
                  </h1>
                  <p className="mb-8 leading-relaxed text-gray-700 text-lg">
                    <span className="shadow-lg">{abstract}</span>
                  </p>
                  <div className='flex w-full'>
                    <div className="flex sm:flex-row justify-between w-full items-center">
                      <a href={url6} target="_blank" className="inline-flex items-center justify-center  text-indigo-600 font-bold py-2 px-6 border-2 border-purple-700 rounded-lg shadow-lg transition-colors duration-300 ease-in-out mt-6 sm:mt-0">
                        <span>Read More</span>
                        <svg className="w-4 h-4 ml-2" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round">
                          <path d="M5 12h14"></path>
                          <path d="M12 5l7 7-7 7"></path>
                        </svg>
                      </a>
                      <Link to={{
                        pathname: `/newsres/${title}/${abstract}/${encodeURIComponent(url6)}/${encodeURIComponent(multimedia_url6)}`
                      }} target="_blank">
                        <button className="bg-purple-700 hover:bg-purple-800 text-white font-bold py-2 px-8 rounded-lg shadow-lg transition-colors duration-300 ease-in-out mt-6 sm:mt-0">
                          Engage
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


    </div>
  )
}

export default SportsnewsItem