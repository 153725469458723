import React from 'react'

export default function Partners() {
  return (
    <div>
        <section className="bg-white dark:bg-gray-900">
        <div className="max-w-screen-xl px-4 pb-8 mx-auto lg:pb-16">
            <div className="grid grid-cols-2 gap-8 text-gray-500 sm:gap-12 sm:grid-cols-3 lg:grid-cols-6 dark:text-gray-400">
                <a href="/" className="flex items-center lg:justify-center">
                    <img src='img/logo-forbes.png' alt='logo-forbes'/>                  
                </a>
                <a href="/" className="flex items-center lg:justify-center">
                    <img src='img/logo-nytimes.png' alt='logo-nytimes'/>                  
                </a>
                <a href="/" className="flex items-center lg:justify-center">
                    <img src='img/logo-insider.png' alt='logo-insider'/>                  
                </a>
    
                <a href="/" className="flex items-center lg:justify-center">
                    <img src='img/logo-wsj.png' alt='logo-wsj'/>                  
                </a>
                <a href="/" className="flex items-center lg:justify-center">
                    <img src='img/logo-usa-today.png' alt='logo-usa-today'/>                  
                </a>
                <a href="/" className="flex items-center lg:justify-center">
                    <img src='img/logo-fox-news.png' alt='logo-fox-news'/>                  
                </a>
            </div>
        </div>
    </section>
    </div>
  )
}
