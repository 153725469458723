import React, { useState} from 'react'
import { Menu } from '@headlessui/react';
import { useLocation, Link } from 'react-router-dom';

import { FaSignInAlt, FaUser, FaUserCircle, FaHome, FaPowerOff, FaUserFriends, FaComments, FaGrav, FaChartLine, FaCogs  } from "react-icons/fa";
import { clearDataFromLocalStorage, getDataFromLocalStorage } from "../../Utils/local-storage";
// import { ChevronDownIcon } from "@heroicons/react/20/solid";

export default function Navbar() {

    const location = useLocation();
    const currentUrl = location.pathname;

    const [active, setActive] = useState(false);
    const showMenu = () => {
        setActive(!active);
      };

      const handleLogout = () => {
        clearDataFromLocalStorage();
        window.location.href = '/';
        
      };

      const user = getDataFromLocalStorage('user');

    function handleClick(e) {
        e.preventDefault();
        if (currentUrl === '/') {
        const targetId = e.currentTarget.getAttribute("href");
        const targetElement = document.querySelector(targetId);
        const targetPosition = targetElement.offsetTop;
        const currentPosition = window.pageYOffset;
        const distance = targetPosition - currentPosition;
        const duration = 1500;
      
        let start = null;
        function step(timestamp) {
          if (!start) start = timestamp;
          const progress = timestamp - start;
          window.scrollTo(0, easeInOutCubic(progress, currentPosition, distance, duration));
          if (progress < duration) window.requestAnimationFrame(step);
        }
        window.requestAnimationFrame(step);
        }else {
            window.location.href = '/';
        }
        
      }
      
      function easeInOutCubic(t, b, c, d) {
        t /= d / 2;
        if (t < 1) return (c / 2) * t * t * t + b;
        t -= 2;
        return (c / 2) * (t * t * t + 2) + b;
      }


  return (
    <div>
        <header className="fixed w-full">
        <nav className="bg-white py-6">
            <div className="flex items-center justify-between max-w-screen-xl  mx-auto">
                <a href="/" className="flex items-center">
                    <img src="/img/nrdsLogo.png" className="h-6 mr-3 sm:h-9" alt="Logo" />
                    <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">inkUrge</span>
                    <h6 className='text-xs ml-1 mb-2'>beta</h6>
                </a>
                <div className="flex items-center lg:order-2">
                    
                    {
                        user ? (
                            <a href="/dashboard" className="text-gray-800 dark:text-white hover:bg-gray-50 focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 sm:mr-2 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-gray-800">Dashboard</a>
                        ): (
                            <a href="/signin" className="text-gray-800 dark:text-white hover:bg-gray-50 focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 sm:mr-2 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-gray-800">Log in</a>  
                        )
                    }
                    {
                        user ? (
                            <p className="text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 sm:mr-2 lg:mr-0 dark:bg-purple-600 dark:hover:bg-purple-700 focus:outline-none dark:focus:ring-purple-800">{user?.result?.packageType === 'Free Trial' ? <a href='/upgrade-pricing'> Upgrade</a> : user?.result?.packageType}</p>
                        ): (
                            <a href="/signup/Free Trial/Monthly" className="text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 sm:mr-2 lg:mr-0 dark:bg-purple-600 dark:hover:bg-purple-700 focus:outline-none dark:focus:ring-purple-800">Get Started Free </a>
                        )
                    }
                    <button onClick={showMenu}
                    data-collapse-toggle="mobile-menu-2" type="button" className="inline-flex items-center p-2 ml-1 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="mobile-menu-2" aria-expanded="false">
                        <span className="sr-only">Open main menu</span>
                        <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd"></path></svg>
                        <svg className="hidden w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                        {active ? "CLOSE" : "MENU"}
                    </button>
                </div>
                <div className="items-center justify-between hidden w-full lg:flex lg:w-auto lg:order-1" id="mobile-menu-2">
                    <ul className="flex flex-col mt-4 font-medium lg:flex-row lg:space-x-8 lg:mt-0">
                        <li>
                            <a href="/" className="block py-2 pl-3 pr-4 text-white bg-purple-700 rounded lg:bg-transparent lg:text-purple-700 lg:p-0 dark:text-white" aria-current="page">Home</a>
                        </li>
                        <li>
                            <Menu as="div" className="relative inline-block text-center">
                                <Menu.Button className="block py-2 pl-3 pr-4 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-purple-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700">
                                Features
                                </Menu.Button>
                                
                                <Menu.Items className='absolute py-4 bg-gray-800 text-gray-200 rounded-xl'>
                            <div ><a className=' block py-2 pl-3 pr-4 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-purple-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700 ' href='/chat'>ChatAI</a></div>
                            <div ><a className='  block py-2 pl-3 pr-4 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-purple-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700 ' href='/alivii'>ALiviI</a></div>
                            <div ><a className='  block py-2 pl-3 pr-4 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-purple-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700 ' href='/news'>News</a></div>
                        </Menu.Items>
                            </Menu>
                        </li>
                        <li>
                        
                        <a href={`${currentUrl === '/' ? '#price': '/'}`} onClick={handleClick} className="block py-2 pl-3 pr-4 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-purple-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700">Pricing</a>
                           
                            
                        </li>
                        <li>
                            <a href={`${currentUrl === '/' ? '#contact': '/'}`} onClick={handleClick}   className="block py-2 pl-3 pr-4 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-purple-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700">Contact</a>
                        </li>
                    </ul>
                </div>
            </div>

                  {/* <!-- Mobile Menu --> */}
                  <div
                      id="menu"
                      className={
                          active
                              ? "relative lg:hidden p-1 mt-6 text-xl  text-black dark:text-white rounded-lg flex-auto h-screen"
                              : "hidden"
                      }
                  >
                      <div className="flex flex-col justify-center text-start mt-6">
                          {user ? (
                              <Link
                                  to="/dashboard"
                                  className="border flex items-center capitalize py-3"
                              >
                                  <FaUser />
                                  {user && user?.result && user && user?.result?.name}
                              </Link>
                          ) : (
                              <Link
                                  to="/signin"
                                  className="border py-3 flex items-center"
                              >
                                  <FaSignInAlt className="mr-3  text-gray-800" />
                                  Sign in
                              </Link>
                          )}
                          {/* {account ? (
            <Menu as="div" className="relative w-full">
              <Menu.Button className="border border-white/25 py-4 w-full text-left">
                {substr(account.toString(), 25)}
              </Menu.Button>
              <Menu.Items className="absolute left-0 z-10 mt-2 p-3 origin-top-right rounded-md shadow-xl shadow-gray-400 dark:shadow-gray-700 bg-gray-200 dark:bg-gray-800/80 backdrop-blur-md">
                <Menu.Item>
                  {({ active }) => (
                    <button
                      className="flex items-center px-6 py-2 bg-primary cursor-pointer hover:bg-gray-800 transition-all rounded-md"
                      onClick={() => disconnectWalletFromApp()}
                    >
                      <FaWallet className="mr-3" />
                      Disconnect
                    </button>
                  )}
                </Menu.Item>
              </Menu.Items>
            </Menu>
          ) : (
            <button
              className="border border-white/25 py-4 flex items-center"
              onClick={() => handleWalletConnect()}
            >
              <FaWallet className="mr-3 text-purple-300" />
              Connect Wallet
            </button>
          )} */}
                          <Link
                          to='/'
                              className="border border-white/25 py-4 flex items-center"
                          >
                              <FaHome className="mr-3 text-purple-300" />
                              Home
                          </Link>
                          <Link
                              onClick={showMenu}
                              className="border border-white/25 py-4 flex items-center"
                              to="/chat"
                          >
                              <FaComments className="mr-3 text-purple-300" />
                              Chat AI
                          </Link>
                          <Link
                              onClick={showMenu}
                              className="border border-white/25 py-4 flex items-center"
                              to="/alivii"
                          >
                              <FaGrav className="mr-3 text-purple-300" />
                              ALiviI
                          </Link>
                          <Link
                              onClick={showMenu}
                              className="border border-white/25 py-4 flex items-center"
                              to="/news"
                          >
                              <FaChartLine className="mr-3 text-purple-300" />
                              News
                          </Link>
                          {/* <Link
                              onClick={showMenu}
                              className="border border-white/25 py-4 flex items-center"
                              to="/community"
                          >
                              <FaUserFriends className="mr-3 text-purple-300" />
                              Community
                          </Link> */}
                          {/* <Link
                              onClick={showMenu}
                              className="border border-white/25 py-4 flex items-center"
                              to="/account"
                          >
                              <FaCogs className="mr-3 text-purple-300" />
                              Settings
                          </Link> */}
                          {/* <button
            onClick={showMenu}
            className="border border-white/25 py-4 flex items-center"
            to="/about"
          >
            Change theme
            <DarkToggle darkToggle={darkToggle} setDarkToggle={setDarkToggle} />
          </button> */}
                          {
                            user && <Link onClick={handleLogout}  className="border border-white/25 py-4 flex items-center">
                            <FaPowerOff className="mr-3 text-purple-300" />
                            Logout
                        </Link> 
                          }
                      </div>
                  </div>
        </nav>
    </header>
    </div>
  )
}
