import React from "react";
import Home from "./Pages/Home";
import { Routes, Route, Navigate } from "react-router-dom";
import SignIn from "./Pages/SignIn";
import SignUp from "./Pages/SignUp";
import Dashboard from "./Pages/Dashboard";
import EmailVerify from "./Pages/EmailVerify";
import ForgetPassword from "./Pages/ForgetPassword";
import ResetPassword from "./Pages/ResetPassword";
import News from './Pages/News';
import NewsRes from './Pages/NewsRes';
import Chatbot from "./Pages/Chatbot";
import PaymentForm from './Pages/payment';
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import PaymentHandler from "./Pages/paymentHandler";
import NotFound from "./Pages/NotFound";
import { getDataFromLocalStorage } from './Utils/local-storage';
import ALiviI from "./Pages/ALiviI";
import MainPage from "./Pages/Sample";
import UpgradePricing from "./Pages/UpgradePricing";
import Upgrade from "./Pages/upgrade";



export default function AllRoutes() {

    const user = getDataFromLocalStorage('user');


    return (
        <div className={``}>

            <Routes>
                <Route
                    path="/"
                    element={
                        <Home />
                    }
                />
                <Route
                    path="/chat"
                    element={
                        <Chatbot />
                    }
                />
                <Route
                    path="/signin"
                    element={!user ? <SignIn /> : <Navigate to="/dashboard" />}
                />

                <Route
                    path="/signup/:packageName/:type"
                    element={<SignUp />}
                />

                <Route
                    path="/forgetpassword"
                    element={!user ? <ForgetPassword /> : <Navigate to="/dashboard" />}
                />

                <Route
                    path="/user/:id/reset/:token"
                    element={!user ? <ResetPassword /> : <Navigate to="/dashboard" />}
                />
                <Route
                    path="/dashboard"
                    element={
                        user ? (
                            <Dashboard
                            />
                        ) : (
                            <Navigate to="/" />
                        )
                    }
                />

                <Route path="/user/verifyEmail/:token" element={<EmailVerify />} /> 


<Route path="/news" element={<News />}/>
<Route exact path="/newsres/:title/:abstract/:url/:multimedia_url" element={<NewsRes />}/>
<Route
    path="/payment"
    element={
        <PaymentForm />
    }
/>
<Route
    path="/privacypolicy"
    element={
        <PrivacyPolicy />
    }
/>

    <Route
    path="/paymentStatus/:paymentStatus/:sessionId"
    element={
        <PaymentHandler />
    }
/>

<Route
    path="*"
    element={
        <NotFound />
    }
/>
        <Route
    path="/alivii"
    element={
        <MainPage />
    }
/>

{/* <Route
    path="/sample"
    element={
        <MainPage />
    }
/> */}


<Route
    path="/upgrade-pricing"
    element={
        <UpgradePricing />
    }
/>

<Route
    path="/upgrade/:packageName/:packageType"
    element={
        <Upgrade />
    }
/>




</Routes>
</div>
);
}
