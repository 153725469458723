import { useState, useEffect } from 'react';

function NotFound() {
  const [animate, setAnimate] = useState(false);
  
  useEffect(() => {
    setAnimate(true);
  }, []);
  
  return (
    <div className="flex flex-col justify-center items-center h-screen">
      <div className="text-center">
        <h1 className="text-9xl font-bold text-gray-900 mb-4">404</h1>
        <h2 className="text-4xl font-bold text-gray-900 mb-8">Page not found</h2>
        <p className="text-lg text-gray-600 mb-8">
          The page you are looking for might have been removed or is temporarily unavailable.
        </p>
        <button
          className={`px-4 py-2 bg-blue-500 text-white rounded-md shadow-md transform ${
            animate ? 'scale-100' : 'scale-0'
          } hover:bg-blue-600 transition-all duration-500`}
          onClick={() => window.location.href = '/'}
        >
          Go back home
        </button>
      </div>
    </div>
  );
}

export default NotFound;