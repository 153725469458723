import React, { useEffect, useState } from 'react'
import { getDataFromLocalStorage } from '../Utils/local-storage';
import AnnualPrice from '../Components/HomePage/AnnualPrice';
import MonthlyPrice from '../Components/HomePage/MonthlyPrice';

const UpgradePricing = () => {
  const [isAnnually, setIsAnnually] = useState(false);

  const checkUserLogin = () => {
    const user = getDataFromLocalStorage('user');
    if (user && user.result) {

    }else {
      window.location.href = '/';
    }
  };

  useEffect(() => {
    checkUserLogin();
  },[]);
  
  const handlePriceChange = () => {
    setIsAnnually(!isAnnually);
  }
  return (
    <div className='mb-6' id="price">
  <section className=" body-font" id='pricingSection'>
    <div className="container px-5 mx-auto">
      <div className="max-w-screen-xl px-4 py-2 mx-auto lg:py-16 lg:px-6">
        <div className="max-w-screen-md mx-auto mb-8 text-center lg:mb-12">
          <h2 className="mb-4 text-3xl font-extrabold tracking-tight text-gray-900">Designed for business teams like yours</h2>
          <p className="mb-5 font-light text-gray-500 sm:text-xl ">Here at inkUrge we focus on markets where technology, innovation, and capital can unlock long-term value and drive economic growth.</p>
        </div>       
        <div className="flex justify-center rounded overflow-hidden">
          <button className={`${!isAnnually ? 'bg-purple-700 text-white': 'text-black-200'} hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 focus:outline-none dark:focus:ring-purple-800`} onClick={handlePriceChange}>Monthly</button>
          <button className={`${isAnnually ? 'bg-purple-700 text-white': 'text-black-200'} hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 focus:outline-none dark:focus:ring-purple-800`} onClick={handlePriceChange}>Annually</button>
        </div>
      </div>
      {
        isAnnually ? <AnnualPrice upgrade={true} /> :  <MonthlyPrice upgrade={true} />
      }
    </div>
  </section>
</div>

  )
}

export default UpgradePricing;