import React, { useState, useEffect } from 'react'
import axios from 'axios'
import HealthnewsItem from './HealthnewsItem'
import HealthnewsItem2 from './HealthnewsItem2'
import HealthnewsItem3 from './HealthnewsItem3'



const HealthnewsList = () => {
    const [results, setArticles] = useState([])
  
    const getArticles = async () => {
      const response = await axios.get(`${process.env.REACT_APP_HEANEWS_URL}?api-key=${process.env.REACT_APP_NEWS_KEY}`)
      
      setArticles(response.data.results)
    }
  
    useEffect(() => {
      getArticles()
    }, [])
    
    return (
      <div className='max-w-screen-2xl flex'>
  <div className="max-w-screen-lg">
    
    <div >
    {results.length > 0 && results.slice(0, 2).map((result) => {
      if (result.multimedia && result.abstract && !result.abstract.includes("?") && !result.title.includes("?")) {
        return (
          <HealthnewsItem
            key={encodeURIComponent(result.url)} 
            title={result.title}
            abstract={result.abstract.slice(0, 200)}
            url={encodeURIComponent(result.url)}
            multimedia_url={encodeURIComponent(result.multimedia[0].url)}
          />
        );
      } else {
        return null;
      }
    })}
  </div>
  <div className='flex px-4 '>
    {results.length > 0 && results.slice(2, 6).map((result) => {
      if (result.multimedia && result.abstract && !result.abstract.includes("?") && !result.title.includes("?")) {
        return (
          <HealthnewsItem2
            key={encodeURIComponent(result.url)} 
            title={result.title}
            abstract={result.abstract.slice(0, 200)}
            url={encodeURIComponent(result.url)}
            multimedia_url={encodeURIComponent(result.multimedia[0].url)}
          />
        );
      } else {
        return null;
      }
    })}
  </div>
  <div >
    {results.length > 0 && results.slice(11, 20).map((result) => {
      if (result.multimedia && result.abstract && !result.abstract.includes("?") && !result.title.includes("?")) {
        return (
          <HealthnewsItem3
            key={encodeURIComponent(result.url)} 
            title={result.title}
            abstract={result.abstract.slice(0, 200)}
            url={encodeURIComponent(result.url)}
            multimedia_url={encodeURIComponent(result.multimedia[0].url)}
          />
        );
      } else {
        return null;
      }
    })}
  </div>
    </div>
    <div className='w-full border-l-2 border rounded-lg ml-8 items-center'>
    <h2 className='text-center'>Advertisments</h2>
  </div>
  </div>
  
    )
  }

export default HealthnewsList