const ChatAiModel = ({ talking, selectModel }) => {
    console.log('== talking ==', talking);
    const url = talking ? selectModel.replace('.png', '.gif'): selectModel;
    console.log('== url ===', url);
    return (
        <>
        {
            talking ? (
                <img
        src={`${url}`}
        alt="avatarModel"
        className="w-full h-auto rounded-xl max-w-2xl"
        width="500"
        height="500"
      />
            ): (
                <img
        src={`${url}`}
        alt="avatarModel"
        className="w-full h-auto rounded-xl max-w-2xl"
        width="500"
        height="500"
      />
            )
        }
        
        </>
    )
}

export default ChatAiModel;