import React from 'react'
// import { Link } from 'react-router-dom'

const BrowserHistoryItem = ({ title, abstract, url, multimedia_url } ) => {
  // const handleClick = (title, url) => {
  //   console.log('Title: ' + title);
  //   console.log('URL: ' + decodeURIComponent(url));
  // }

  const url0 = decodeURIComponent(url);
  const multimedia_url0 = decodeURIComponent(multimedia_url);

  return (
    <div className=" max-w-screen-2xl flex items-center py-8 ml-3">
    <div className="max-w-screen-md ">
      <h1 className="title-font sm:text-4xl text-2xl mb-4 font-medium text-gray-900">{title}</h1>
      <p className="mb-8 leading-relaxed">{abstract}</p>
      <div className="flex ">
        <a className="text-purple-700 items-center mr-2 md:mb-2 lg:mb-0" href={url0}>
          Read More
          <svg className="w-4 h-4 ml-2" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round">
            <path d="M5 12h14"></path>
            <path d="M12 5l7 7-7 7"></path>
          </svg>
        </a> 
      </div>
    </div>
    <div className=" flex w-full ">
      <img className="w-64 rounded-lg h-24" alt="hero" src={multimedia_url0} />
    </div>
  </div>
  )
}

export default BrowserHistoryItem;