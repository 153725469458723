import React from 'react';


function AiModel ({ talking }) {

    return (
        talking ? (
            <div className='justify-between  px-4 py-3'>
            <div className="py-6 justify-center">
                <div className="max-w-lg overflow-hidden ">
                    <img src="/img/newResModel.gif" alt={'aiModel'} className="w-full h-full object-cover rounded-lg" />
                </div>
            </div>
        </div>
        ): (
            <div className='justify-between px-4 py-3'>
            <div className="flex flex-wrap justify-center">
                <div className="max-w-lg overflow-hidden">
                    <img src="/img/newResModel.jpg" alt={'aiModel'} className="w-full h-full rounded-lg py-3object-cover" />
                </div>
            </div>
        </div>
        )
        
    );
}


export default AiModel;