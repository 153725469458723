import React, { useState } from 'react';
import axios from 'axios';
import WordLoader from './WordLoader';
import { URLS } from '../../Utils/constants';
import { getUserAuthToken } from '../../Utils/axios';

function PDFLoader(props) {

    const { title, abstract, newsTitle, newsLink, newImage } = props;

    const [loader, setLoader] = useState(false);
    const [error, setError]=useState('');

    // download pdf from api response
    const handlePdfDownload = async () => {
        try {
            if (!title.length > 0 && !abstract.length > 0) {
                alert('First generate the AI response');
                return;
            } 
            setLoader(true);
            const textForPdf = `Title ${title}\n\n Abstract \n  ${abstract}`;
            //http(URLS.download.pdf, 'POST', { text: textForPdf })
            const auth = getUserAuthToken();
          const response = await axios.post(URLS.download.pdf, { text: textForPdf, newsTitle, newsLink, newImage },{
            headers: {
                Authorization: 'Bearer ' + auth  
            },
            responseType: 'blob', // tell axios to expect binary data
          });
    
          const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
          const url = window.URL.createObjectURL(pdfBlob);
          const a = document.createElement('a');
          a.href = url;
          a.download = 'output.pdf';
          a.click();
          window.URL.revokeObjectURL(url);
          setLoader(false);
        } catch (error) {
          console.error(error);
          setLoader(false);
          setError(error);
        }
      };
    
    return (
    <div>
        <div className='w-full items-center px-2 flex justify-center'>
            <h1 className='text-sm text-grey-800 mr-2 py-2'>Export</h1>
        <WordLoader title={title} abstract={abstract} newsTitle={newsTitle} newsLink={newsLink} newImage={newImage} />
            <button className=' text-lg font-bold' onClick={handlePdfDownload}>
                {loader?(
                    <>DOWNLOADING</>
                ) : (
                    <>PDF</>
                )}
            </button>

            {error!==''&&(
                <div className='error-msg text-xs mt-8'>{error}</div>
                )}
        </div>
    </div>

    
    );
}


export default PDFLoader
