import React, { useEffect, useState } from "react";
import { getDataFromLocalStorage } from '../../Utils/local-storage';
import DownloadHistoryItem from "./downloadHistoryItem";
import * as ApiUtil from '../../Utils/axios';
import { URLS } from "../../Utils/constants";

 function DownloadHistory () {

    const [responseData, setResponseData] = useState([]);
  const getTitleFromBackend = async () => {
   
    
    
    const response = await ApiUtil.http(URLS.dashboard.downloadHistory,
      'POST', {});
      console.log('= response ==',response);
    if (!response.hasError) {
        setResponseData(response.data);
    
    }
  };

  useEffect(() => {
    getTitleFromBackend();
  }, []);


return (
    <div className='flex w-full max-w-screen-2xl'>
  <div className="">
   {
    responseData.map((ele) => {
        return (
            <DownloadHistoryItem 
            title={ele.newsTitle}
        abstract={ele.currentAbstract}
        url={ele.fileName}
        multimedia_url={ele.newImage} />
        );
    })
   }

    </div>
    </div>
);


}


export default DownloadHistory;