import React from "react";
import { Link } from "react-router-dom";

export default function PageLink({ title, to, Icon, onClick, show_navlabel }) {
  return (
    <Link
      title={title}
      to={to}
      onClick={onClick}
      className={
        show_navlabel
          ? "hover:bg-white/25 p-[13px] rounded-lg flex items-center w-full hover:no-underline"
          : "hover:bg-white/25 p-[13px] justify-center rounded-lg flex items-center w-full hover:no-underline"
      }
    >
      {Icon}
      {show_navlabel && <span className="ml-3">{title}</span>}
    </Link>
  );
}
