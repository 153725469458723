import React, { useState, useEffect } from 'react'
import axios from 'axios'
import InsidernewsItem from './InsidernewsItem'
import InsidernewsItem2 from './InsidernewsItem2'
import InsidernewsItem3 from './InsidernewsItem3'



const InsidernewsList = () => {
  const [results, setArticles] = useState([])

  const getArticles = async () => {
    const response = await axios.get(`${process.env.REACT_APP_INSNEWS_URL}?api-key=${process.env.REACT_APP_NEWS_KEY}`)
    
    setArticles(response.data.results)
  }

  useEffect(() => {
    getArticles()
  }, [])
  
  return (
    <div className='flex'>
  <div className="max-w-screen-xl flex flex-wrap">
  
  <div className='flex'>
  {results.length > 0 && results.slice(0, 3).map((result) => {
    if (result.multimedia && result.abstract && !result.abstract.includes("?") && !result.title.includes("?")) {
      return (
        <InsidernewsItem
          key={encodeURIComponent(result.url)} 
          title={result.title}
          abstract={result.abstract.slice(0, 200)}
          url={encodeURIComponent(result.url)}
          multimedia_url={encodeURIComponent(result.multimedia[0].url)}
        />
      );
    } else {
      return null;
    }
  })}
</div>
<div className='flex max-w-screen-lg'>
  {results.length > 0 && results.slice(3, 8).map((result) => {
    if (result.multimedia && result.abstract && !result.abstract.includes("?") && !result.title.includes("?")) {
      return (
        <InsidernewsItem2
          key={encodeURIComponent(result.url)} 
          title={result.title.slice(0, 55)}
          abstract={result.abstract.slice(0, 150)}
          url={encodeURIComponent(result.url)}
          multimedia_url={encodeURIComponent(result.multimedia[0].url)}
        />
      );
    } else {
      return null;
    }
  })}
</div>
<div className='max-w-screen-lg'>
  {results.length > 0 && results.slice(8, 30).map((result) => {
    if (result.multimedia && result.abstract && !result.abstract.includes("?") && !result.title.includes("?")) {
      return (
        <InsidernewsItem3
          key={encodeURIComponent(result.url)} 
          title={result.title}
          abstract={result.abstract.slice(0, 200)}
          url={encodeURIComponent(result.url)}
          multimedia_url={encodeURIComponent(result.multimedia[0].url)}
        />
      );
    } else {
      return null;
    }
  })}
</div>
  </div>
  <div className='w-full border rounded-lg items-center'>
    <h2 className='text-center'>Advertisments</h2>
  </div>
</div>

  )
}

export default InsidernewsList