import React from 'react'
import { Link } from 'react-router-dom'

const SciencenewsItem2 = ({ title, abstract, url, multimedia_url } ) => {
  // const handleClick = (title, url) => {
  //   console.log('Title: ' + title);
  //   console.log('URL: ' + decodeURIComponent(url));
  // }

  const url5 = decodeURIComponent(url);
  const multimedia_url5 = decodeURIComponent(multimedia_url);

  return (
    <div className=''>
    <section className="text-gray-600 body-font">
<div className="container py-6 mx-auto">
  <div className="">
    <div className="ml-4 p-4 w-full">
      <a className="relative rounded overflow-hidden">
        <img alt="ecommerce" className=" rounded-lg" src={multimedia_url5}/>
      </a>
      <div className="mt-4">
        <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1">Science</h3>
        <h2 className="text-gray-900 title-font text-md font-medium">{title}</h2>
        <div className="flex justify-between flex-wrap ">                  
                  <a className="text-purple-700 inline-flex text-sm items-center md:mb-2 lg:mb-0" href={url5}>Read More
                    <svg className="w-4 h-4 ml-2" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round">
                      <path d="M5 12h14"></path>
                      <path d="M12 5l7 7-7 7"></path>
                    </svg>
                  </a>
                  <Link to={{
                    pathname: `/newsres/${title}/${abstract}/${encodeURIComponent(url5)}/${encodeURIComponent(multimedia_url5)}`
                    
                  }} target="_blank">
                    <button className='w-full px-4 items-end text-sm text-indigo-600 font-bold bg-yellow-200 cursor-pointer justify-center border-2 border-purple-700 rounded'
                    >Engage</button>
                  </Link>
                  
                </div>
      </div>
    </div>  
  </div>
</div>
</section>


  </div>
  )
}

export default SciencenewsItem2