export const getDataFromLocalStorage = (key) => {
    return JSON.parse(localStorage.getItem(key));
};

export const setDataInLocalStorage = (key, data) => {
     localStorage.setItem(key, JSON.stringify(data));
};


export const setDataInSessionStorage = (key, data) => {
    sessionStorage.setItem(key, JSON.stringify(data));
};

export const getDataInSessionStorage = (key) => {
    return JSON.parse(sessionStorage.getItem(key));
};

export const clearDataFromLocalStorage = () => {
    localStorage.removeItem('user');
  };