import React, { useEffect, useState } from "react";
import { ClipLoader } from "react-spinners";
import Nav from "../Components/Navbar/Nav";
import Sidebar from "../Components/Sidebar";
import UserHistory from "../Components/UserDashboard/UserHistory";
import { http } from "../Utils/axios";
import { URLS } from "../Utils/constants";
// import  BrowserHistory from "../Components/UserDashboard/browserHistory";
// import DownloadHistory from "../Components/UserDashboard/downloadHistory";
// import DashboardCard01 from "../Components/UserDashboard/DashboardCard01";
// import { useModal } from "../context/ModalContext";
import { getDataFromLocalStorage, setDataInLocalStorage } from "../Utils/local-storage";

function Dashboard() {
  //const { account } = useModal();
  const [comingSoon, setComingSoon] = useState(false);
  const [balance, setBalance] = useState(0);
  const [showBalance, setShowBalance] = useState(false);
  const showPopup = () => {
    setComingSoon(!comingSoon);
  };
  const [show_navlabel, setShowNavbar] = useState(true);
  const toggleNavbar = () => {
    setShowNavbar(!show_navlabel);
  };
  const substr = (str, n) => {
    return str.length > n ? str.substr(0, n - 1) : str;
  }

  let user = getDataFromLocalStorage('user');

  const getUserInfos = async () => {
    const response = await http(URLS.user.getInfo, 'POST');
    if (!response.hasError) {
      setDataInLocalStorage('user', response.data);
    }
    user = getDataFromLocalStorage('user');
    let balanceWords = user?.result?.balanceToken;
    balanceWords = parseInt(balanceWords);
    balanceWords = balanceWords * 1.35;
    balanceWords = parseInt(balanceWords);
    setBalance(balanceWords > 0 ? balanceWords: 0);
    setShowBalance(true);
  }

  useEffect(() => {
    getUserInfos();
  }, []);

  
  return (
    <div
      // style={{
      //   backgroundImage:
      //     "url(https://images.unsplash.com/photo-1536183922588-166604504d5e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1169&q=80)",
      // }}
      className="max-w-screen-2xl flex flex-col text-gray-800" 
    >
      <Nav
        showPopup={showPopup}
        comingSoon={comingSoon}
        toggleNavbar={toggleNavbar}
        show_navlabel={show_navlabel}
      />

      <div
        className={
          show_navlabel
            ? "grid lg:grid-cols-body gap-7 p-3 md:p-7 w-full"
            : "grid lg:grid-cols-bodyClosed gap-7 p-7 w-full"
        }
        
      >
         {/* <DashboardCard01 /> */}
        <div className="max-w-screen-2xl flex" >
        <div className=" text-2xl py-3 rounded-lg text-white" style={{ backgroundColor: "#125a58" }} >
        <Sidebar show_navlabel={show_navlabel} toggleNavbar={toggleNavbar} />
        </div>
        <div className="rounded-lg" style={{ backgroundColor: "#99aa9f" }} >
        <div className="flex justify-between px-5 py-8 ml-3" >
            <div>
              <h2 className="flex text-2xl font-bold capitalize">
                Welcome  &nbsp; <h1 className="ml-2 text-purple-600">{user?.result && user?.result.name}</h1> ..!
              </h2>
              
            </div>
            <div className="">
                <h2 className="text-lg font-semibold tracking-wide">
                  Total <span className="text-2xl font-bold ml-3 mr-3 text-purple-500">WORDS</span>{" "}
                  Balance
                </h2>
                <h2 className="my-1 text-3xl tracking-wider">{  user ? showBalance ? balance : <ClipLoader color="#00ff00" size={30}  /> : '' }</h2>
                {/* <h2 className="text-xl ">= $0.000000</h2> */}
                {
              user && (
                <>
                <h1 className="ml-2 text-purple-600"> { user.result.packageType} : <span> {user.result.subscriptionStatus.toUpperCase()}</span> </h1> 
                </>
              )
            }
                {/* <div className="mt-8" >
                  <button className="text-white bg-purple-700 mr-3 font-medium  transition-all text-sm px-5 py-2 rounded-full ">
                    Deposit
                  </button>
                  <button className="border  bg-white/25 bg-purple-100 mr-3 font-medium  transition-all text-sm px-5 py-2 rounded-full">
                    Withdraw
                  </button>
                  <button className="border bg-white/25 bg-purple-100 mr-3 font-medium  transition-all text-sm px-5 py-2 rounded-full">
                    Transfer
                  </button>
                </div> */}
              </div>
            {/* <div>
              <div className="text-end">
                <h2 className="text-xl font-semibold tracking-wide">
                  Total Value (BTC)
                </h2>
                <h2 className="my-1 text-3xl tracking-wider">0.00</h2>
                <h2 className="text-xl ">= $0.000000</h2>
              </div>
            </div> */}
          </div>
          <div className="max-w-screen-2xl py-8">
          <UserHistory />
          <div className=" max-w-screen-xl px-5 py-8 flex  ">
            <div
              style={{
                backgroundImage:
                  "url(https://images.unsplash.com/photo-1617396900799-f4ec2b43c7ae?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80)",
              }}
              className=" w-full bg-cover items-start ml-3 justify-start py-8 px-4 rounded-lg"
            >
              Explore with inkUrge
            </div>
            <div
              style={{
                backgroundImage:
                  "url(https://images.unsplash.com/photo-1516450360452-9312f5e86fc7?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80)",
              }}
              className="w-full object-cover items-start justify-start ml-3 py-8 px-4 rounded-lg"
            >
              inkUrge Events
            </div>
          </div>
        </div>
        </div> 
        </div>
        
        
      </div>
    </div>
  );
}

export default Dashboard;
