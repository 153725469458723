const baseUrl = 'https://inkurge.backendservices.online';

export const URLS = {
    baseUrls: {
        backend: baseUrl
    },
    newsUrls: {
        getTitle: `${baseUrl}/title`,
        getAbstract: `${baseUrl}/abstract`,
        reGenerate: `${baseUrl}/regenerate`,
    },
    paymentUrl: {
        verifyPaymentSession: `${baseUrl}/user/verify/paymentSession`,
        upgrade: `${baseUrl}/upgrade`,
    },
    dashboard: {
        downloadHistory: `${baseUrl}/fileHistory`
    },
    user: {
        signUp: `${baseUrl}/user/signup`,
        signIn: `${baseUrl}/user/signin`,
        verifyEmail: `${baseUrl}/user/verify/email`,
        getInfo: `${baseUrl}/user/getInfo`,
    },
    chat: {
        chitChat: `${baseUrl}/chitchat`,
        textChat: `${baseUrl}/textChat`,
        hearResponse: `${baseUrl}/hear`,
    },
    extra: {
        contact: `${baseUrl}/contact-us`,
        subscribe: `${baseUrl}/subscribe`
    },
    download: {
        pdf: `${baseUrl}/convertToPDF`,
        word: `${baseUrl}/convertToWord`,
    }
};