import React, { useState } from 'react';
import axios from 'axios';
import { URLS } from '../../Utils/constants';
import { getUserAuthToken } from '../../Utils/axios';

function WordLoader(props) {
    const { title, abstract, newsTitle, newsLink, newImage } = props;

    const [loader, setLoader] = useState(false);
    const [error, setError]=useState('');

    const handleWordDownload = async () => {
        try {
            if (!title.length > 0 && !abstract.length > 0) {
                alert('First generate the AI response');
                return;
            } 
            setLoader(true);
            const auth = getUserAuthToken();
            const textForWord = `Title ${title}\n\n Abstract \n  ${abstract}`;
          const response = await axios.post(URLS.download.word, { text: textForWord, newsTitle, newsLink, newImage },{
            headers: {
              Authorization: 'Bearer ' + auth  
          },
            responseType: 'blob', // tell axios to expect binary data
          });
    
          const pdfBlob = new Blob([response.data], { type: 'application/docx' });
          const url = window.URL.createObjectURL(pdfBlob);
          const a = document.createElement('a');
          a.href = url;
          a.download = 'output.docx';
          a.click();
          window.URL.revokeObjectURL(url);
          setLoader(false);
        } catch (error) {
          console.error(error);
          setLoader(false);
          setError(error);
        }
      };

  return (
    <div className='mt-1 ml-10'>
        <button className='text-blue-800 text-lg font-bold mr-4' onClick={handleWordDownload}>
                {loader?(
                    <>DOWNLOADING</>
                ) : (
                    <>DOCx</>
                )}
            </button>

            {error!==''&&(
                <div className='error-msg text-xs mt-8'>{error}</div>
                )}
    </div>
  )
}

export default WordLoader