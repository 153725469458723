import { Fragment, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { FaSignInAlt } from "react-icons/fa";
import SignInForm from "../SignInForm";

function SignIn() {

  const [isOpen, setIsOpen] = useState(false);

  function handleModal() {
    setIsOpen(!isOpen);
  }
  return (
    <Menu as="div" className="relative inline-block text-right text-sm">
      <div>
        <Menu.Button className="flex items-center border-b-2 border-white hover:border-purple-800 transition-all dark:hover:border-purple-500 dark:hover:border-b-2 mx-3 py-1 dark:border-b-2 dark:border-primary">
          <FaSignInAlt className="mr-2" />
          Sign in
          <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="w-[350px] p-3 h-auto absolute right-0 z-10 mt-2 origin-top-right rounded-lg shadow-xl shadow-gray-400 dark:shadow-gray-700 bg-gray-200 dark:bg-gray-800/80 backdrop-blur-md">
          <SignInForm handleModal={handleModal} />
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

export default SignIn;
