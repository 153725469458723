import React from 'react';

import HeadlineNewsList from './HeadlineNews/HeadlineNewsList';
import BusinessNewsList from './BusinessNews/BusinessnewsList';
import InsidernewsList from './InsiderNews/InsidernewsList';
import PoliticsnewsList from './PoliticsNews/PoliticsnewsList';
import HealthnewsList from './HealthNews/HealthnewsList';
import SciencenewsList from './ScienceNews/SciencenewsList';
import SportsnewsList from './SportsNews/SportsnewsList';
import TechnewsList from './TechnologyNews/TechnewsList';
import WorldnewsList from './WorldNews/WorldnewsList';

const BlogcategoryTabs = () => {
  const [openTab, setOpenTab] = React.useState(1);

  return (
    <div className=''>

          <ul className="max-w-screen-lg flex ml-6 justify-between my-10" style={{
      'maxWidth': '100%',
      'display': 'flex',
      'flexWrap': 'wrap',
      'listStyleType': 'none',
      'padding': 0,
    }} role="tablist">

      
        {/* Headlines */}
      <li>
        <a
          style={{
            'display': 'inline-block',
            'padding': '10px 20px',
            'color': openTab === 1 ? '#7C3AED' : '#4B5563',
            'borderBottom': openTab === 1 ? '2px solid #7C3AED' : 'none',
            'textAlign': 'center',
            'textDecoration': 'none',
            'fontSize': '16px',
            'fontWeight': openTab === 1 ? 'bold' : 'normal',
            'cursor': 'pointer',
          }}
          onClick={(e) => {
            e.preventDefault();
            setOpenTab(1);
          }}
          href="#link1"
          role="tablist"
        >
          Headlines
        </a>
      </li>

       {/* Business */}
       <li>
        <a
          style={{
            'display': 'inline-block',
            'padding': '10px 20px',
            'color': openTab === 2 ? '#7C3AED' : '#4B5563',
            'borderBottom': openTab === 2 ? '2px solid #7C3AED' : 'none',
            'textAlign': 'center',
            'textDecoration': 'none',
            'fontSize': '16px',
            'fontWeight': openTab === 2 ? 'bold' : 'normal',
            'cursor': 'pointer',
          }}
          onClick={(e) => {
            e.preventDefault();
            setOpenTab(2);
          }}
          href="#link2"
          role="tablist"
        >
          Business
        </a>
      </li>

      {/* Insider */}
      <li>
        <a
          style={{
            'display': 'inline-block',
            'padding': '10px 20px',
            'color': openTab === 3 ? '#7C3AED' : '#4B5563',
            'borderBottom': openTab === 3 ? '2px solid #7C3AED' : 'none',
            'textAlign': 'center',
            'textDecoration': 'none',
            'fontSize': '16px',
            'fontWeight': openTab === 3 ? 'bold' : 'normal',
            'cursor': 'pointer',
          }}
          onClick={(e) => {
            e.preventDefault();
            setOpenTab(3);
          }}
          href="#link3"
          role="tablist"
        >
          Insider
        </a>
      </li>

      {/* Politics */}
      <li>
        <a
          style={{
            'display': 'inline-block',
            'padding': '10px 20px',
            'color': openTab === 4 ? '#7C3AED' : '#4B5563',
            'borderBottom': openTab === 4 ? '2px solid #7C3AED' : 'none',
            'textAlign': 'center',
            'textDecoration': 'none',
            'fontSize': '16px',
            'fontWeight': openTab === 4 ? 'bold' : 'normal',
            'cursor': 'pointer',
          }}
          onClick={(e) => {
            e.preventDefault();
            setOpenTab(4);
          }}
          href="#link4"
          role="tablist"
        >
          Politics
        </a>
      </li>

      {/* Health */}
      <li>
        <a
          style={{
            'display': 'inline-block',
            'padding': '10px 20px',
            'color': openTab === 5 ? '#7C3AED' : '#4B5563',
            'borderBottom': openTab === 5 ? '2px solid #7C3AED' : 'none',
            'textAlign': 'center',
            'textDecoration': 'none',
            'fontSize': '16px',
            'fontWeight': openTab === 5 ? 'bold' : 'normal',
            'cursor': 'pointer',
          }}
          onClick={(e) => {
            e.preventDefault();
            setOpenTab(5);
          }}
          href="#link5"
          role="tablist"
        >
          Health
        </a>
      </li>

      {/* Science */}
      <li>
        <a
          style={{
            'display': 'inline-block',
            'padding': '10px 20px',
            'color': openTab === 6 ? '#7C3AED' : '#4B5563',
            'borderBottom': openTab === 6 ? '2px solid #7C3AED' : 'none',
            'textAlign': 'center',
            'textDecoration': 'none',
            'fontSize': '16px',
            'fontWeight': openTab === 6 ? 'bold' : 'normal',
            'cursor': 'pointer',
          }}
          onClick={(e) => {
            e.preventDefault();
            setOpenTab(6);
          }}
          href="#link6"
          role="tablist"
        >
          Science
        </a>
      </li>

      {/* Sports */}
      <li>
        <a
          style={{
            'display': 'inline-block',
            'padding': '10px 20px',
            'color': openTab === 7 ? '#7C3AED' : '#4B5563',
            'borderBottom': openTab === 7 ? '2px solid #7C3AED' : 'none',
            'textAlign': 'center',
            'textDecoration': 'none',
            'fontSize': '16px',
            'fontWeight': openTab === 7 ? 'bold' : 'normal',
            'cursor': 'pointer',
          }}
          onClick={(e) => {
            e.preventDefault();
            setOpenTab(7);
          }}
          href="#link7"
          role="tablist"
        >
          Sports
        </a>
      </li>

      {/* Technology */}
      <li>
        <a
          style={{
            'display': 'inline-block',
            'padding': '10px 20px',
            'color': openTab === 8 ? '#7C3AED' : '#4B5563',
            'borderBottom': openTab === 8 ? '2px solid #7C3AED' : 'none',
            'textAlign': 'center',
            'textDecoration': 'none',
            'fontSize': '16px',
            'fontWeight': openTab === 8 ? 'bold' : 'normal',
            'cursor': 'pointer',
          }}
          onClick={(e) => {
            e.preventDefault();
            setOpenTab(8);
          }}
          href="#link8"
          role="tablist"
        >
          Technology
        </a>
      </li>

      {/* World */}
      <li>
        <a
          style={{
            'display': 'inline-block',
            'padding': '10px 20px',
            'color': openTab === 9 ? '#7C3AED' : '#4B5563',
            'borderBottom': openTab === 9 ? '2px solid #7C3AED' : 'none',
            'textAlign': 'center',
            'textDecoration': 'none',
            'fontSize': '16px',
            'fontWeight': openTab === 9 ? 'bold' : 'normal',
            'cursor': 'pointer',
          }}
          onClick={(e) => {
            e.preventDefault();
            setOpenTab(9);
          }}
          href="#link9"
          role="tablist"
        >
          World
        </a>
      </li>





    </ul>



















      {/* <ul className="max-w-screen-lg flex ml-6 justify-between my-10" role="tablist">
      <li>
          <a
            className={
              `bg-purple-800 text-purple-800 font-medium`
            }
            onClick={(e) => {
              e.preventDefault();
              setOpenTab(1);
            }}
            data-toggle="tab"
            href="#link1"
            role="tablist"
          >
           <p style={{
            'display': 'inline-block',
            'padding': '10px 20px',
            'color': 'white',
            'border-radius': '5px',
            'text-align': 'center',
            'text-decoration': 'none',
            'font-size': '16px',
            'font-weight': 'bold',
            'cursor': 'pointer',
           }} className='bg-purple-700'>Headlines</p> 
          </a>
        </li>
        <li>
          <a
            className={
              'font-medium text-gray-500 hover:bg-transparent hover:text-blue-500' +
              (openTab === 2
                ? 'text-base  text-purple-800 border-purple-500 font-semibold border-b-2'
                : 'bg-gray')
            }
            onClick={(e) => {
              e.preventDefault();
              setOpenTab(2);
            }}
            data-toggle="tab"
            href="#link2"
            role="tablist"
          >
            Business
          </a>
        </li>
        <li >
          <a
            className={
              ' font-medium  dark:hover:bg-transparent dark:hover:text-purple-500' +
              (openTab === 3
                ? 'text-base text-purple-800 border-purple-500 border-b-2 font-semibold'
                : 'bg-white')
            }
            onClick={(e) => {
              e.preventDefault();
              setOpenTab(3);
            }}
            data-toggle="tab"
            href="#link3"
            role="tablist"
          >
            Insider
          </a>
        </li>
        <li>
          <a
            className={
              'font-medium dark:text-gray-500 hover:bg-gray-200 dark:hover:bg-transparent dark:hover:text-purple-500' +
              (openTab === 4
                ? 'text-base  text-purple-800 border-purple-500 font-semibold border-b-2'
                : 'bg-white')
            }
            onClick={(e) => {
              e.preventDefault();
              setOpenTab(4);
            }}
            data-toggle="tab"
            href="#link4"
            role="tablist"
          >
            Politics
          </a>
        </li>
        <li>
          <a
            className={
              'font-medium dark:text-gray-500 hover:bg-gray-200 dark:hover:bg-transparent dark:hover:text-purple-500' +
              (openTab === 5
                ? 'text-base  text-purple-800 border-purple-500 font-semibold border-b-2'
                : 'bg-white')
            }
            onClick={(e) => {
              e.preventDefault();
              setOpenTab(5);
            }}
            data-toggle="tab"
            href="#link5"
            role="tablist"
          >
            Health
          </a>
        </li>
        <li>
          <a
            className={
              'font-medium dark:text-gray-500 hover:bg-gray-200 dark:hover:bg-transparent dark:hover:text-purple-500' +
              (openTab === 6
                ? 'text-base  text-purple-800 border-purple-500 font-semibold border-b-2'
                : 'bg-white')
            }
            onClick={(e) => {
              e.preventDefault();
              setOpenTab(6);
            }}
            data-toggle="tab"
            href="#link6"
            role="tablist"
          >
            Science
          </a>
        </li>
        <li>
          <a
            className={
              'font-medium dark:text-gray-500 hover:bg-gray-200 dark:hover:bg-transparent dark:hover:text-purple-500' +
              (openTab === 7
                ? 'text-base  text-purple-800 border-purple-500 font-semibold border-b-2'
                : 'bg-white')
            }
            onClick={(e) => {
              e.preventDefault();
              setOpenTab(7);
            }}
            data-toggle="tab"
            href="#link7"
            role="tablist"
          >
            Sports
          </a>
        </li>
        <li>
          <a
            className={
              'font-medium text-gray-500 hover:bg-gray-200 dark:hover:bg-transparent hover:text-purple-500' +
              (openTab === 8
                ? 'text-base  text-purple-800 border-purple-500 font-semibold border-b-2'
                : 'bg-white')
            }
            onClick={(e) => {
              e.preventDefault();
              setOpenTab(8);
            }}
            data-toggle="tab"
            href="#link8"
            role="tablist"
          >
            Technology
          </a>
        </li>
        <li>
          <a
            className={
              'font-medium dark:text-gray-500 hover:bg-gray-200 dark:hover:bg-transparent dark:hover:text-purple-500' +
              (openTab === 9
                ? 'text-base  text-purple-800 border-purple-500 font-semibold border-b-2'
                : 'bg-white')
            }
            onClick={(e) => {
              e.preventDefault();
              setOpenTab(9);
            }}
            data-toggle="tab"
            href="#link9"
            role="tablist"
          >
            World
          </a>
        </li>
      </ul> */}
      <div className={openTab === 1 ? " p-6 lg:px-10 py-10 lg:py-5 text-gray-600 dark:text-gray-400" : "hidden"} id="link1">
        <HeadlineNewsList />
      </div>
      <div className={openTab === 2 ? " p-6 lg:px-10 py-10 lg:py-5 text-gray-600 dark:text-gray-400" : "hidden"} id="link2">
        <BusinessNewsList />
      </div>
      <div className={openTab === 3 ? " p-6 lg:px-10 py-10 lg:py-5 text-gray-600 dark:text-gray-400" : "hidden"} id="link3">
        <InsidernewsList />
      </div>
      <div className={openTab === 4 ? " p-6 lg:px-10 py-10 lg:py-5 text-gray-600 dark:text-gray-400" : "hidden"} id="link4">
        <PoliticsnewsList />
      </div>
      <div className={openTab === 5 ? " p-6 lg:px-10 py-10 lg:py-5 text-gray-600 dark:text-gray-400" : "hidden"} id="link5">
        <HealthnewsList />
      </div>
      <div className={openTab === 6 ? " p-6 lg:px-10 py-10 lg:py-5 text-gray-600 dark:text-gray-400" : "hidden"} id="link6">
        <SciencenewsList />
      </div>
      <div className={openTab === 7 ? " p-6 lg:px-10 py-10 lg:py-5 text-gray-600 dark:text-gray-400" : "hidden"} id="link7">
        <SportsnewsList />
      </div>
      <div className={openTab === 8 ? " p-6 lg:px-10 py-10 lg:py-5 text-gray-600 dark:text-gray-400" : "hidden"} id="link8">
        <TechnewsList />
      </div>
      <div className={openTab === 9 ?  "p-6 lg:px-10 py-10 lg:py-5 text-gray-600 dark:text-gray-400" : "hidden"} id="link9">
        <WorldnewsList />
      </div>
    </div>
  );
};

export default BlogcategoryTabs;
