export const AllLanguages = [
    { name: 'English', value: 'english', lang: 'en-US' },
    { name: 'Afrikaans', value: 'afrikaans', lang: 'af-ZA' },
    { name: 'Albanian', value: 'albanian', lang: 'sq-AL' },
    { name: 'Amharic', value: 'amharic', lang: 'am-ET' },
    { name: 'Arabic', value: 'arabic', lang: 'ar-SA' },
    { name: 'Armenian', value: 'armenian', lang: 'hy-AM' },
    { name: 'Azerbaijani', value: 'azerbaijani', lang: 'az-AZ' },
    { name: 'Bashkir', value: 'bashkir' },
    { name: 'Basque', value: 'basque', lang: 'eu-ES' },
    { name: 'Belarusian', value: 'belarusian', lang: 'be-BY' },
    { name: 'Bengali', value: 'bengali', lang: 'bn-BD' },
    { name: 'Bosnian', value: 'bosnian', lang: 'bs-BA' },
    { name: 'Bulgarian', value: 'bulgarian', lang: 'bg-BG' },
    { name: 'Burmese', value: 'burmese', lang: 'my-MM' },
    { name: 'Catalan', value: 'catalan', lang: 'ca-ES' },
    { name: 'Central Khmer', value: 'central_khmer', lang: 'km-KH' },
    { name: 'Chinese', value: 'chinese', lang: 'zh-CN' },
    { name: 'Corsican', value: 'corsican' },
    { name: 'Croatian', value: 'croatian', lang: 'hr-HR' },
    { name: 'Czech', value: 'czech', lang: 'cs-CZ' },
    { name: 'Danish', value: 'danish', lang: 'da-DK' },
    { name: 'Dutch', value: 'dutch', lang: 'nl-BE' },
    { name: 'Esperanto', value: 'esperanto', lang: 'eo' },
    { name: 'Estonian', value: 'estonian', lang: 'et-EE' },
    { name: 'Finnish', value: 'finnish', lang: 'fi-FI' },
    { name: 'French', value: 'french', lang: 'fr-FR' },
    { name: 'Galician', value: 'galician', lang: 'gl-ES' },
    { name: 'Georgian', value: 'georgian', lang: 'ka-GE' },
    { name: 'German', value: 'german', lang: 'de-DE' },
    { name: 'Greek', value: 'greek', lang: 'el-GR' },
    { name: 'Gujarati', value: 'gujarati', lang: 'gu-IN' },
    { name: 'Haitian', value: 'haitian' },
    { name: 'Hausa', value: 'hausa', lang: 'ha-Latn-NG' },
    { name: 'Hebrew', value: 'hebrew', lang: 'he-IL' },
    { name: 'Hindi', value: 'hindi', lang: 'hi-IN' },
    { name: 'Hungarian', value: 'hungarian', lang: 'hu-HU' },
    { name: 'Icelandic', value: 'icelandic', lang: 'is-IS' },
    { name: 'Igbo', value: 'igbo', lang: 'ig-NG' },
    { name: 'Indonesian', value: 'indonesian', lang: 'id-ID' },
    { name: 'Irish', value: 'irish', lang: 'ga-IE' },
    { name: 'Italian', value: 'italian', lang: 'it-IT' },
    { name: 'Japanese', value: 'japanese', lang: 'ja-JP' },
    { name: 'Javanese', value: 'javanese', lang: 'jv-ID' },
    { name: 'Kannada', value: 'kannada', lang: 'kn-IN' },
    { name: 'Kazakh', value: 'kazakh', lang: 'kk-KZ' },
    { name: 'Kinyarwanda', value: 'kinyarwanda', lang: 'rw-RW' },
    { name: 'Kirundi', value: 'kirundi' },
{ name: 'Korean', value: 'korean', lang: 'ko-KR' },
{ name: 'Kurdish', value: 'kurdish', lang: 'ku-Arab-IQ' },
{ name: 'Lao', value: 'lao', lang: 'lo-LA' },
{ name: 'Latin', value: 'latin' },
{ name: 'Latvian', value: 'latvian', lang: 'lv-LV' },
{ name: 'Lingala', value: 'lingala' },
{ name: 'Lithuanian', value: 'lithuanian', lang: 'lt-LT' },
{ name: 'Luxembourgish', value: 'luxembourgish', lang: 'lb-LU' },
{ name: 'Macedonian', value: 'macedonian', lang: 'mk-MK' },
{ name: 'Malagasy', value: 'malagasy', lang: 'mg-MG' },
{ name: 'Malay', value: 'malay', lang: 'ms-MY' },
{ name: 'Malayalam', value: 'malayalam', lang: 'ml-IN' },
{ name: 'Maltese', value: 'maltese', lang: 'mt-MT' },
{ name: 'Manx', value: 'manx' },
{ name: 'Maori', value: 'maori', lang: 'mi-NZ' },
{ name: 'Marathi', value: 'marathi', lang: 'mr-IN' },
{ name: 'Mongolian', value: 'mongolian', lang: 'mn-MN' },
{ name: 'Nepali', value: 'nepali', lang: 'ne-NP' },
{ name: 'Norwegian', value: 'norwegian', lang: 'nb-NO' },
{ name: 'Nyanja', value: 'nyanja' },
{ name: 'Oriya', value: 'oriya' },
{ name: 'Oromo', value: 'oromo', lang: 'om-ET' },
{ name: 'Ossetian', value: 'ossetian' },
{ name: 'Pali', value: 'pali' },
{ name: 'Pashto', value: 'pashto', lang: 'ps-AF' },
{ name: 'Persian', value: 'persian', lang: 'fa-IR' },
{ name: 'Polish', value: 'polish', lang: 'pl-PL' },
{ name: 'Portuguese', value: 'portuguese', lang: 'pt-BR' },
{ name: 'Punjabi', value: 'punjabi', lang: 'pa-IN' },
{ name: 'Quechua', value: 'quechua', lang: 'quz-PE' },
{ name: 'Romanian', value: 'romanian', lang: 'ro-RO' },
{ name: 'Romansh', value: 'romansh' },
{ name: 'Russian', value: 'russian', lang: 'ru-RU' },
{ name: 'Samoan', value: 'samoan', lang: 'sm-WS' },
{ name: 'Sango', value: 'sango' },
{ name: 'Sanskrit', value: 'sanskrit' },
{ name: 'Scottish Gaelic', value: 'scottish_gaelic' },
{ name: 'Serbian', value: 'serbian', lang: 'sr-RS' },
{ name: 'Shona', value: 'shona', lang: 'sn-ZW' },
{ name: 'Sindhi', value: 'sindhi', lang: 'sd-PK' },
{ name: 'Sinhalese', value: 'sinhalese' },
{ name: 'Slovak', value: 'slovak', lang: 'sk-SK' },
{ name: 'Slovenian', value: 'slovenian', lang: 'sl-SI' },
{ name: 'Somali', value: 'somali', lang: 'so-SO' },
{ name: 'Spanish', value: 'spanish', lang: 'es-AR' },
{ name: 'Sundanese', value: 'sundanese', lang: 'su-ID' },
{ name: 'Swedish', value: 'swedish', lang: 'sv-FI' },
{ name: 'Tagalog', value: 'tagalog' },
{ name: 'Tajik', value: 'tajik', lang: 'tg-TJ' },
{ name: 'Tamil', value: 'tamil', lang: 'ta-IN' },
{ name: 'Tatar', value: 'tatar', lang: 'tt-RU' },
{ name: 'Telugu', value: 'telugu', lang: 'te-IN' },
{ name: 'Thai', value: 'thai', lang: 'th-TH' },
{ name: 'Tibetan', value: 'tibetan' },
{ name: 'Tigrinya', value: 'tigrinya', lang: 'ti-ER' },
{ name: 'Tonga', value: 'tonga' },
{ name: 'Tswana', value: 'tswana' },
{ name: 'Turkish', value: 'turkish', lang: 'tr-TR' },
{ name: 'Turkmen', value: 'turkmen', lang: 'tk-TM' },
{ name: 'Uighur', value: 'uighur' },
{ name: 'Ukrainian', value: 'ukrainian', lang: 'uk-UA' },
{ name: 'Urdu', value: 'urdu', lang: 'ur-PK' },
{ name: 'Uzbek', value: 'uzbek', lang: 'uz-UZ-Cyrl' },
{ name: 'Vietnamese', value: 'vietnamese', lang: 'vi-VN' },
{ name: 'Welsh', value: 'welsh', lang: 'cy-GB' },
{ name: 'Wolof', value: 'wolof', lang: 'wo-SN' },
{ name: 'Xhosa', value: 'xhosa', lang: 'xh-ZA' },
{ name: 'Yiddish', value: 'yiddish', lang: 'yi-001' },
{ name: 'Yoruba', value: 'yoruba', lang: 'yo-NG' },
{ name: 'Zulu', value: 'zulu', lang: 'zu-ZA' }
];


export const freeTrialLanguages = [
    { name: 'English', value: 'english', lang: 'en-US' },
    { name: 'Afrikaans', value: 'afrikaans', lang: 'af-ZA' },
    { name: 'Albanian', value: 'albanian', lang: 'sq-AL' },
    { name: 'Amharic', value: 'amharic', lang: 'am-ET' },
    { name: 'Arabic', value: 'arabic', lang: 'ar-SA' },
    { name: 'Armenian', value: 'armenian', lang: 'hy-AM' },
    { name: 'Azerbaijani', value: 'azerbaijani', lang: 'az-AZ' },
    { name: 'Bashkir', value: 'bashkir' },
    { name: 'Basque', value: 'basque', lang: 'eu-ES' },
    { name: 'Belarusian', value: 'belarusian', lang: 'be-BY' },
    { name: 'Bengali', value: 'bengali', lang: 'bn-BD' },
    { name: 'Bosnian', value: 'bosnian', lang: 'bs-BA' },
    { name: 'Bulgarian', value: 'bulgarian', lang: 'bg-BG' },
    { name: 'Burmese', value: 'burmese', lang: 'my-MM' },
    { name: 'Catalan', value: 'catalan', lang: 'ca-ES' },
    { name: 'Central Khmer', value: 'central_khmer', lang: 'km-KH' },
    { name: 'Chinese', value: 'chinese', lang: 'zh-CN' },
    { name: 'Corsican', value: 'corsican' },
    { name: 'Croatian', value: 'croatian', lang: 'hr-HR' },
    { name: 'Czech', value: 'czech', lang: 'cs-CZ' },
    { name: 'Danish', value: 'danish', lang: 'da-DK' },
    { name: 'Dutch', value: 'dutch', lang: 'nl-BE' },
    { name: 'Esperanto', value: 'esperanto', lang: 'eo' },
    { name: 'Estonian', value: 'estonian', lang: 'et-EE' },
    { name: 'Finnish', value: 'finnish', lang: 'fi-FI' },
    { name: 'French', value: 'french', lang: 'fr-FR' },
];