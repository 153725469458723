import React from 'react'

export default function OnenewsItem ({ title, abstract, url, multimedia_url }) {
  
  return (
    <div className='max-w-screen-xl'>
      <section className="text-gray-600 body-font">
        <div className="container px-4 mt-2 mx-auto">
          <div className="flex flex-wrap -m-4">
            <div className="p-4 md:w-full h-100">
              <div className="h-full  border-opacity-80 rounded-lg overflow-hidden">
                <img className=" max-w-md rounded-lg object-cover object-center" src={multimedia_url} alt="news" />
                <div className="py-6">
                  <h2 className="tracking-widest text-xs title-font font-medium text-gray-400 mb-1">Loading...</h2>
                  <h1 class="text-2xl sm:text-2xl font-medium text-gray-900 mb-4 leading-tight">
          <span className="shadow-lg">{title}</span>
        </h1>
        <p class="mb-8 leading-relaxed text-gray-700 text-md">
          <span className="shadow-lg">{abstract}</span>
        </p>
                  <div className="flex justify-between flex-wrap ">
                    <a className="text-purple-700 inline-flex items-center md:mb-2 lg:mb-0" href={url}>Read More
                      <svg className="w-4 h-4 ml-2" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path d="M5 12h14"></path>
                        <path d="M12 5l7 7-7 7"></path>
                      </svg>
                    </a>
                    

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


    </div>
  )
}
