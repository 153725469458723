import React from 'react'
import { Link } from 'react-router-dom'

const SciencenewsItem = ({ title, abstract, url, multimedia_url } ) => {
  // const handleClick = (title, url) => {
  //   console.log('Title: ' + title);
  //   console.log('URL: ' + decodeURIComponent(url));
  // }

  const url5 = decodeURIComponent(url);
  const multimedia_url5 = decodeURIComponent(multimedia_url);

  return (
    <div className="flex flex-col lg:flex-row items-center mx-auto lg:px-20 my-16">
  <div className="lg:w-2/3 lg:pr-24 ">
    <h1 className="text-3xl sm:text-4xl font-medium text-gray-900 mb-4 leading-tight">
      <span className="shadow-lg">{title}</span>
    </h1>
    <p className="mb-8 leading-relaxed text-gray-700 text-lg">
      <span className="shadow-lg">{abstract}</span>
    </p>
    <div className="flex sm:flex-row justify-between items-center">
      <a className="inline-flex items-center justify-center text-lg font-bold text-indigo-600 bg-yellow-200 py-2 px-6 border-2 border-purple-700 rounded-lg shadow-lg transition-colors duration-300 ease-in-out mt-6 sm:mt-0" href={url5} target="_blank">
        <span>Read More</span>
        <svg className="w-4 h-4 ml-2" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round">
          <path d="M5 12h14"></path>
          <path d="M12 5l7 7-7 7"></path>
        </svg>
      </a>
      <Link to={{
        pathname: `/newsres/${title}/${abstract}/${encodeURIComponent(url5)}/${encodeURIComponent(multimedia_url5)}`
      }} target="_blank">
        <button className="bg-purple-700 text-white text-lg font-bold py-2 px-6 rounded-lg shadow-lg hover:bg-purple-800 mr-3 transition-colors duration-300 ease-in-out mt-6 sm:mt-0">
          Engage
        </button>
      </Link>
    </div>
  </div>
  <div className="max-w-screen-md flex items-center">
    <img className="max-w-lg object-cover rounded-lg shadow-lg" alt="hero" src={multimedia_url5} />
  </div>
</div>
  )
}

export default SciencenewsItem