import React from "react";
import "tailwindcss/tailwind.css";
import Navbar from "../Components/Navbar/Navbar";
import Footer from "../Components/Navbar/Footer";

function PrivacyPolicy() {
  return (
    <div className="items-center">
        <Navbar />

        <div className="max-w-screen-2xl text-left ml-6 mt-24 bg-white rounded-lg shadow-lg p-6">
      <h1 className="text-3xl font-bold mb-4 text-center ">Privacy Policy</h1>
      <p className="mb-2 mt-6">
        At Nordus Labs Inc., we are committed to protecting the privacy of our
        users' personal information.
      </p>
      <p className="mb-2">
        This Privacy Policy explains how we collect, use, and protect your
        personal information in connection with your use of our SaaS product,
        inkUrge, and our website.
      </p>
      <h2 className="text-2xl font-bold my-4">Information We Collect</h2>
      <p className="mb-2">
        We may collect personal information from you when you register an
        account with us or use our services. This information may include your
        name, email address, phone number, etc.
      </p>
      <h2 className="text-2xl font-bold my-4">How We Use Your Information</h2>
      <p className="mb-2">
        We may use your personal info6rmation to provide you with the services
        you have requested, to communicate with you about your account or our
        services, to improve our services, and for other purposes consistent
        with this Privacy Policy.
      </p>
      <h2 className="text-2xl font-bold my-4">How We Protect Your Information</h2>
      <p className="mb-2">
        We take reasonable and appropriate measures to protect your personal
        information from loss, misuse, unauthorized access, disclosure,
        alteration, and destruction, whether in transit or at rest.
      </p>
      <h2 className="text-2xl font-bold my-4">Your Choices</h2>
      <p className="mb-2">
        You may choose to not provide us with certain information, or to
        request that we delete or modify your information, subject to our
        legal and contractual obligations.
      </p>
      <h2 className="text-2xl font-bold my-4">Changes to this Policy</h2>
      <p className="mb-2">
        We may update this Privacy Policy from time to time, and any changes
        will be effective upon posting to our website or through our SaaS
        product. Your continued use of our services after any such update
        constitutes your acceptance of the new Privacy Policy.
      </p>
      <p>
      if you have any questions or feedback, don't hesitate to reach out to us at info@inkurge.com.
      </p>
    </div>
    <Footer />
    </div>
  );
}

export default PrivacyPolicy;
