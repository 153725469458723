// import { Route, Routes } from 'react-router-dom';
import './App.css';
// import Home from './Pages/Home';
// import News from './Pages/News';
// import NewsRes from './Pages/NewsRes';
import AllRoutes from './Router';
// import { loadStripe } from '@stripe/stripe-js';
// import {  Elements } from '@stripe/react-stripe-js';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


//const stripePromise = loadStripe('pk_test_51MdHulAsGvY1EWoEhdglDN5xLLe6fClChqHJGUT3QprAsSmdc7rDMpZBAA9x9BHS6XGQ51FISB43J1s8yGMusisD00lqoyCraZ');

function App() {
  return (
    <div>
     
      {/* <Elements stripe={stripePromise}> */}
      <ToastContainer />
      <AllRoutes />
      {/* </Elements> */}
      {/* <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/news" element={<News />}/>
        <Route exact path="/newsres/:title/:abstract/:url/:multimedia_url" element={<NewsRes />}/>
      </Routes> */}
      
    </div>
  );
}

export default App;

