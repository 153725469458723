import axios from 'axios';

const getDataFromLocalStorage = (key) => {
    try {
      const dataFromLocalStorage = JSON.parse(window.localStorage.getItem(key));
      return {
        hasError: dataFromLocalStorage ? false : true,
        data: dataFromLocalStorage
      };
    } catch (err) {
      return {};
    }
  };


 export const getUserAuthToken = () => {
    try {
      const localDataResponse = getDataFromLocalStorage(
        'user'
      );
      if (!localDataResponse.hasError) {
        const data = localDataResponse.data;
        return data.token;
      } else {
        return "";
      } 

    } catch (err) {
      return ""; //returning empty token in case not found
    }
  };
  
  export const saveDataIntoLocalStorage = (key, value) => {
    try {
      //window.localStorage.saveItem(key, JSON.stringify(value)); not working
      localStorage.setItem(key, JSON.stringify(value));
    } catch (err) {
      console.log(
        'error while saving data with key',
        key,
        'and value',
        value,
        'into local storage'
      );
    }
  };
  
  
 const clearDataFromLocalStorage = () => {
    localStorage.removeItem('user');
  };



export const http = async (
    apiUrl,
    callType, //POST or GET
    data,
    headers = {}
  ) => {
    try {
      const authToken = getUserAuthToken();
      
      headers["Authorization"] = `Bearer ` + authToken;

        const axiosCallObject = {
          method: callType,
          url: apiUrl,
          data: data,
          headers,
        };
  
      const axiosCallResponse = await axios(axiosCallObject);
  
      if (
        axiosCallResponse &&
        axiosCallResponse.data &&
        axiosCallResponse.data.status
      ) {
        return {
          hasError: false,
          authenticated: true,
          ...axiosCallResponse.data,
        };
      }
  
      let isUserAuthenticated = true;
      const errorMessageResponse =
        axiosCallResponse.data && axiosCallResponse.data.message;
      if (
        errorMessageResponse &&
        errorMessageResponse.includes('jwt expired') || errorMessageResponse.includes('Authentication Failed')
      ) {
        isUserAuthenticated = false;
      }
  
      if (!isUserAuthenticated) {
        clearDataFromLocalStorage();
        window.location.href = "/signin";
      }
  
      return {
        hasError: true,
        errorMessage:
          (axiosCallResponse.data && axiosCallResponse.data.message) ||
          "Unable to complete the request",
        authenticated: isUserAuthenticated,
      };
    } catch (err) {
      console.log("error while calling api", err);
      const errorMessage =
        err?.response?.data?.data ||
        err?.response?.data?.message ||
        err?.response?.data?.data?.message ||
        "Unable to complete the request";
      //as we are not sure here that cause of API failure is due to authentication
      //or something else
      //that is why we are assuming user is currently authenticated
      //Currently in api endpoints all requests are returned with 200 status
      //irrespective of their state (unauthentication or error)

      if (typeof errorMessage === 'string') {
        if (
          errorMessage &&
          errorMessage.includes('jwt expired') || errorMessage?.includes('Authentication Failed')
        ) {
          clearDataFromLocalStorage();
          window.location.href = "/signin";
        }
      } else if (
        errorMessage &&
        errorMessage?.message.includes('jwt expired') || errorMessage?.message.includes('Authentication Failed')
      ) {
        clearDataFromLocalStorage();
        window.location.href = "/signin";
      }
  
      return {
        hasError: true,
        errorMessage: errorMessage,
        authenticated: true,
      };
    }
  };
