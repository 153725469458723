import React, { useState } from 'react';
import Navbar from "../Components/Navbar/Navbar";
// import Footer from "../Components/Navbar/Footer";
// import OnenewsItem from "../Components/Newspage/OnenewsItem";
import PDFLoader from '../Components/Newspage/PDFLoader';
// import Language from '../Components/Language';
// import { useParams } from 'react-router-dom';
import { AllLanguages, freeTrialLanguages } from './langues';
import { getDataFromLocalStorage } from '../Utils/local-storage';
import * as ApiUtil from '../Utils/axios';
import * as allUrls from '../Utils/constants';
import { ClipLoader } from 'react-spinners';

export default function Chatbot() {

//   const { title, abstract, url, multimedia_url } = useParams();
  const [messageChat, setMessageChat] = useState('');
  const [responseChat, setResponseChat] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isGeneratingResponse, setIsGeneratingResponse] = useState(false);
  const [hearResponse, setHearResponse] = useState('');
  //const [messageAbstract, setMessageAbstract] = useState(`${abstract.slice(0, 200)}`);
//   const [responseAbstract, setResponseAbstract] = useState('');
const [currentLanguage, setCurrentLanguage] = useState('english');

const userPackage = getDataFromLocalStorage('user') && getDataFromLocalStorage('user').result.packageType || 'Free Trial';

    const playAudio = (url) => {
      try {
        const audio = new Audio(url);
        audio.play();
      } catch (error) {
        console.log('== error in playing audio ==', error);
      }
}

  const generateHearResponse = async () => {
    if (hearResponse !== '') {
      playAudio(hearResponse);
    }else {
      if (responseChat !== '') {
        setIsGeneratingResponse(true);
        const backendResponse = await ApiUtil.http(allUrls.URLS.chat.hearResponse, 'POST', { text: responseChat, language: currentLanguage });
      if (!backendResponse.hasError) {
        setHearResponse(backendResponse.data);
        setIsGeneratingResponse(false);
          playAudio(backendResponse.data);
      }else {
        setIsGeneratingResponse(false);
      }
     
      }
    }

  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (messageChat !== '') {
      setResponseChat('');
      setHearResponse('');
      setIsLoading(true);
      const backendResponse = await ApiUtil.http(allUrls.URLS.chat.textChat, 'POST', { messageChat, currentLanguage });
      if (backendResponse.hasError) {
        setIsLoading(false);
        setResponseChat(backendResponse.errorMessage || backendResponse.error);
        if (backendResponse.errorMessage === 'Authentication Failed') {
          alert('Please Login first');
        }
      }else {
        setIsLoading(false);
        setResponseChat(backendResponse.data);
      }
    }else {
      setResponseChat('Write anything first');
    }

  };

  return (
    <div>
      <div className="w-full">
        <div>

          {/* //   <BusinessnewsItem
            //     key={result.url}
            //     title={result.title}
            //     abstract={result.abstract.slice(0, 200)}
            //     url={result.url}
            //     multimedia_url={result.multimedia[0].url}
                  <textarea
                    className='px=4 mt-8 border-purple-600 border-2 rounded mb-4'
                    value={messageTitle}
                    onChange={(e) => setMessageTitle(e.target.value)}
                  ></textarea>
                  <textarea
                    className='px=4 mt-8 border-purple-600 border-2 rounded mb-4'
                    value={messageAbstract}
                    onChange={(e) => setMessageAbstract(e.target.value)}
                  ></textarea>
            //   /> */}

            <Navbar />
          <div className="max-w-screen-xl items-center px-4 pb-8 mx-auto lg:gap-8 xl:gap-0 lg:py-8 lg:grid-cols-2 lg:pt-10">
            <div className="max-w-screen-md px-4 pt-20 mx-auto">
              
              <div className="App">
                <h1 className=" max-w-2xl mb-4 text-4xl font-extrabold leading-none tracking-tight md:text-5xl xl:text-6xl dark:text-white "> Chat AI</h1>
                <h3 className=" max-w-2xl mb-6 font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400">Your Multi-Lingual AI Companion</h3>
                <div >
                <h4 className='mt-6 text-bold justify-center text-gray-600 rounded-xl text-2xl'> {  isLoading ? (
                  <ClipLoader color="#00ff00" size={60}  />
                ):   responseChat} </h4>
                </div>
                <PDFLoader responseChat = {responseChat} />
                {
                  responseChat !== '' && responseChat !== 'Authentication Failed'  && (
                    isGeneratingResponse ? (
                      <ClipLoader color="#00ff00" size={60}  />
                      ): (
                        <button onClick={generateHearResponse} className={`swirl-in-bck bg-purple-800 w-10/12 sm:w-8/12 md:w-10/12 lg:w-8/12 xl:w-10/12 2xl:w-8/12 px-1 py-1 font-bold text-md border-2 border-blue-800  rounded-xl text-purple-500 mt-2 mb-2`}>
                    <h4>Hear Response</h4>
                </button>
                      )
                    
                    
                  )
                }
                
                <div className=''>
                <form className='' onSubmit={handleSubmit}>
                <div>
                <textarea
                    className='px-4  w-full text-gray-600 border-gray-900 border rounded mb-4 text-center'
                    placeholder='Ask me Anything..?'
                    value={messageChat}
                    onChange={(e) => setMessageChat(e.target.value)}
                    style={{ paddingTop: '10px' }}
                  ></textarea>
                <button className='hover:text-white hover:bg-purple-800 border border-gray-200 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-purple-600  focus:outline-none' type="submit">Generate Response</button>
                </div>
                  {/* <textarea
                    className='px=4 mt-8 border-purple-600 border-2 rounded mb-4'
                    value={messageAbstract}
                    onChange={(e) => setMessageAbstract(e.target.value)}
                  ></textarea> */}
                </form>
                <h4 className='mt-2 sm:text-sm'>Choose Language</h4>
                <select onChange={(ele) => {setCurrentLanguage(ele.target.value)}} className="mr-3 text-sm  bg-transparent border-2 text-black outline-none cursor-pointer">
                  {
                    userPackage === 'Free Trial' ?  freeTrialLanguages.map((ele) => {
                      return <option className="text-black" value={`${ele.value}`}>{ele.name}</option>;
                    }) : AllLanguages.map((ele) => {
                      return <option className="text-black" value={`${ele.value}`}>{ele.name}</option>;
                    })
                  }
           
            </select>
              </div>
                
            
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}