import React from "react";
import { useState } from "react";
import { URLS } from "../../Utils/constants";

export default function Subscribe() {
  const [subForm, setSubForm] = useState({
    firstName: "",
    lastName: "",
    email: "",
  });

  const [message, setMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    //post request to send email
    const res = await fetch(
      URLS.extra.subscribe,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: subForm.firstName + " " + subForm.lastName,
          email: subForm.email,
        }),
      }
    );
    const data = await res.json();
    console.log('== data ===', data);
    if (data.status === 422 || !data) {
      setMessage("Message not sent");
    } else {
      setMessage(data.data);
      alert('Thank You!')
    }
  };
  const changeHandler = (e) => {
    setSubForm({ ...subForm, [e.target.name]: e.target.value });
  };
  const messageHandler = () => {
    setMessage("");
  };

  return (
    <div className="w-full flex flex-col py-14 md:2 text-sm y-20 items-center bg-gray-800 text-purple-800 dark:text-gray-600 border-b-2 border-gray-400 ">
      <div className="grid md:gap-10 md:grid-cols-2  max-w-screen-xl">
        <div>
          <h1 className="text-4xl font-bold mt-5 dark:text-purple-500">
             <span>SUBSCRIBE..! </span>
          </h1>
          <p className="mt-5 text-gray-200">
          & get access to premium content and insights.
          </p>
        </div>
        <div className="md:p-2 mx-auto mt-5 md:mt-0">
          <form
            onSubmit={handleSubmit}
            action="mailto:info@inkurge.com"
            method="post"
            encType="text/plain"
          >
            <div className="grid lg:grid-cols-2  w-full">
              <input
                placeholder="First Name"
                type="text"
                name="firstName"
                onChange={changeHandler}
                required
                className="contactFormInput rounded"
              />
              <input
                placeholder="Last Name"
                type="text"
                name="lastName"
                onChange={changeHandler}
                required
                className="contactFormInput rounded"
              />
              <input
                placeholder="Email"
                type="Email"
                name="email"
                required
                onChange={changeHandler}
                className="contactFormInput rounded px-4"
              />
            </div>           
            <input
              required
              type="checkbox"
              className="my-8 rounded"
              id="agree"
              name="agree"
            />
            <label htmlFor="agree" className="text-gray-200"> I agree to the privacy policy</label>
            <br />

            {message && (
              <div
                onClick={messageHandler}
                className="w-full h-screen bg-black/30 z-50 fixed top-0 left-0 flex items-center justify-center"
              >
                <div className="bg-gray-800/80 p-3 shadow-xl shadow-purple-700/30 backdrop-blur-md rounded-xl">
                </div>
              </div>
            )}

            <button
              disabled={message}
              type="submit"
              className="bg-purple-500 hover:bg-purple-700 dark:bg-purple-500 text-gray-800 transition-all px-6 py-3 text-sm  border rounded-full hover:text-white dark:hover:bg-purple-600 hover:shadow-lg hover:shadow-purple-500/25"
            >
              Subscribe
            </button>
          </form>
          <br />
          <p className="text-sm text-gray-200">
            You can also email us directly at{" "}
            <a href="mailto:info@inkurge.com" className="text-blue-500">
              info@inkurge.com
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}
