import React, { useEffect, useRef, useState } from 'react';
import Navbar from "../Components/Navbar/Navbar";
import Footer from "../Components/Navbar/Footer";
import OnenewsItem from "../Components/Newspage/OnenewsItem";
import PDFLoader from '../Components/Newspage/PDFLoader';
import { useParams } from 'react-router-dom';
//import Language from '../Components/Language';
import { AllLanguages, freeTrialLanguages } from './langues';
import * as ApiUtil from '../Utils/axios';
import * as allUrls from '../Utils/constants';
import NewResWordCount from '../Components/NewsResButtons';
import { getDataFromLocalStorage, getDataInSessionStorage, setDataInSessionStorage, setDataInLocalStorage, clearDataFromLocalStorage } from '../Utils/local-storage';
import AiModel from '../Components/AiModel';
import './style.css'
import { ClipLoader } from 'react-spinners';

const getInitialData = (type, key) => {
  if (type === 't') {
    // For Title
   const titleData = getDataInSessionStorage(`title__${key}`);
   if (titleData != null) {
      return titleData[0];
   }else {
    return "";
   }
  }else {
    // For Abstract

    const abstractData = getDataInSessionStorage(`abstract__${key}`);
    if (abstractData != null) {
       return abstractData[0];
    }else {
     return "";
    }

  }
};

export default function NewsRes() {

  const { title, abstract, url, multimedia_url } = useParams();
  const [currentLanguage, setCurrentLanguage] = useState('english');
  // const [messageTitle, setMessageTitle] = useState(`${title}`);
  const [responseTitle, setResponseTitle] = useState(getInitialData('t', title));
  //const [messageAbstract, setMessageAbstract] = useState(`${abstract.slice(0, 200)}`);
  const [responseAbstract, setResponseAbstract] = useState(getInitialData('a', title));
  const [titleLoading, setTitleLoading] = useState(false);
  const [AbstractLoading, setAbstractLoading] = useState(false);
  const [wordCount, setWordCount] = useState(300);
  const [firstTimeSubmit, setFirstTimeSubmit] = useState(true);
  const [talkingModel, setTalkingModel] = useState(false);
  const [hearResponse, setHearResponse] = useState('');
  const [isGeneratingResponse, setIsGeneratingResponse] = useState(false);
  const audioRef = useRef(null);

  const userPackage = getDataFromLocalStorage('user') && getDataFromLocalStorage('user').result.packageType || 'Free Trial';

  // useEffect (() => {
  //   if (responseTitle != null && responseTitle !== '') {
  //     const language = AllLanguages.filter((ele) => {return ele.value === currentLanguage});

  //     speak(language[0].lang, "female", `${responseTitle}`, setTalkingModel);
  //   }
    
  // }, [responseTitle])

  //  useEffect (() => {
  //   if (responseAbstract != null && responseAbstract !== '') {
  //     const language = AllLanguages.filter((ele) => {return ele.value === currentLanguage});

  //     speak(language[0].lang, "male", `${responseAbstract}`, setTalkingModel);
  //   }
    
  // }, [responseAbstract])


  const getTitleFromBackend = async () => {
    let url;
    let body;
    setHearResponse('');
    setResponseTitle('');
    if (audioRef.current) {
      audioRef.current.pause();
      setTalkingModel(false);
    }
    if (firstTimeSubmit) {
      url = allUrls.URLS.newsUrls.getTitle;
      body = { messageTitle: title, currentLanguage: currentLanguage };
    }else {
      url = allUrls.URLS.newsUrls.reGenerate
      body = { message: title, currentLanguage: currentLanguage, type: 'Title', wordCount: 20 };
    }
    setTitleLoading(true);
    const titleResponse = await ApiUtil.http(url,
      'POST', body);
    setTitleLoading(false);
    if (!titleResponse.hasError) {
      setResponseTitle(titleResponse.data);
      setDataInSessionStorage(`title__${title}`, [titleResponse.data]);
    
    }else {
      if (titleResponse.errorMessage === 'Authentication Failed') {
         clearDataFromLocalStorage();
        alert('You need to login first');
      }else {
        setResponseTitle(titleResponse.errorMessage);
      }
    }
    setFirstTimeSubmit(false);
  };

  const getAbstractFromBackend = async () => {
    let url;
    let body;
    setResponseAbstract('');
    if (firstTimeSubmit) {
      url = allUrls.URLS.newsUrls.getAbstract;
      body = { messageAbstract: `${abstract.slice(0, 200)}`, currentLanguage: currentLanguage, wordCount: wordCount };
    }else {
      url = allUrls.URLS.newsUrls.reGenerate
      body = { message: `${abstract.slice(0, 200)}`, currentLanguage: currentLanguage, type: 'Abstract', wordCount: wordCount };
    }
    setAbstractLoading(true);
    const response = await ApiUtil.http(url, 'POST', body);
    setAbstractLoading(false);
    if (!response.hasError) {
      setResponseAbstract(response.data);
      setDataInSessionStorage(`abstract__${title}`, [response.data]);
    }else {
      setResponseTitle(response.errorMessage);
    }
  };

  const handleSave = () => {
    const userEmail = getDataFromLocalStorage('user')?.result.email;
    let saveData = getDataFromLocalStorage(`savedAiResponses_news_${userEmail}`);
    if (responseAbstract !== '' && responseAbstract !== '') {
      if (saveData != null) {
        const dataLength = saveData.data.length;
        if (saveData.data[dataLength -1].url !== url) {
          saveData.data.push({
            aiTitle: responseTitle,
            aiAbstract: responseAbstract,
            currentTitle: title,
            currentAbstract: abstract,
            url, 
            multimedia_url,
  
  
          });
          setDataInLocalStorage(`savedAiResponses_news_${userEmail}`, saveData);
        }
        
      }else {
        setDataInLocalStorage(`savedAiResponses_news_${userEmail}`, 
          {
           data: [{
            aiTitle: responseTitle,
            aiAbstract: responseAbstract,
            currentTitle: title,
            currentAbstract: abstract,
            url, 
            multimedia_url,
           }]
          }
        );
      }
      alert('Data is stored in your browser cache temporary.');
    }
    
  };

  const playAudio = (url) => {
    try {
      if (audioRef.current) {
        audioRef.current.pause();
      }
      const audio = new Audio(url);
      audio.play();
      audioRef.current = audio;
      audio.addEventListener('ended', () => {
        setTalkingModel(false);
      });
      audio.addEventListener('play', () => {
        setTalkingModel(true);
      });
    } catch (error) {
      console.log('== error in playing audio ==', error);
    }
  };
  
  const stopAudio = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      setTalkingModel(false);
    }
  };

const generateHearResponse = async () => {
  if (hearResponse !== '') {
    playAudio(hearResponse);
  }else {
    if (responseAbstract !== '' && responseTitle !== '') {
      setIsGeneratingResponse(true);
      const backendResponse = await ApiUtil.http(allUrls.URLS.chat.hearResponse, 'POST', { text: `Starting Reading Title \n${responseTitle}. Now Starting Reading Abstract \n${responseAbstract}`, language: currentLanguage });
    if (!backendResponse.hasError) {
      setHearResponse(backendResponse.data);
      setIsGeneratingResponse(false);
        playAudio(backendResponse.data);
    }else {
      setIsGeneratingResponse(false);
    }
   
    }
  }

};

  const handleSubmit = async (e) => {
    e.preventDefault();
    await Promise.all(getTitleFromBackend(), getAbstractFromBackend());
    //await 
  };

  return (
    <div className="max-w-screen-2xl">
    <Navbar />
    <div className="flex justify-between px-4 rounded-xl">
      <div className="max-w-screen-sm py-6 mt-12 bg-white rounded-xl  text-center">
        <h1 className="bg-gray-700 font-bold text-blue-300 text-xl rounded-xl animated-text"> Selected Article </h1>
        <OnenewsItem
          key={decodeURIComponent(url)}
          title={title}
          abstract={abstract.slice(0, 200)}
          url={decodeURIComponent(url)}
          multimedia_url={decodeURIComponent(multimedia_url)}
        />
      </div>
      <div className="flex max-w-screen-md px-4 justify-center items-center">
    <div className="w-full bg-white rounded-xl px-2 py-3 text-center justify-center">
    <AiModel talking={talkingModel} />
    { responseTitle !== '' && responseAbstract !== '' && (
      <>
      {
        isGeneratingResponse && <ClipLoader color="#00ff00" size={60}  />
      }
      {
        !isGeneratingResponse && <button onClick={ talkingModel ? stopAudio:generateHearResponse}  className={`${responseTitle !== '' && responseAbstract !== '' ? 'swirl-in-bck' : '' } bg-purple-800 w-10/12 sm:w-8/12 md:w-10/12 lg:w-8/12 xl:w-10/12 2xl:w-8/12 px-1 py-1 font-bold text-md border-2 border-blue-800 bg-gray-800 rounded-xl text-purple-500 mt-2 mb-2`}>
        <h4>{talkingModel ? 'Stop': 'Hear Response'}</h4>
      </button> 
      }
      </>
    )
      
    }
      <h1 className=" font-bold text-blue-300 text-xl rounded-xl mb-6">Max Words</h1>
      <NewResWordCount setWordCount={setWordCount} />
      <button
        className="bg-purple-700 hover:bg-purple-800 text-purple-800 font-bold py-3 px-4 rounded-full mb-2 shadow-lg duration-300 ease-in-out mt-2 sm:mt-0"
        type="button"
        onClick={handleSubmit}
      >
        {firstTimeSubmit ? 'Submit' : 'Regenerate'}
      </button>
      <div className=' px-20'>
      <h4 className='mb-2 text-center text-sm lg:text-left lg:mb-0'>Translate Language</h4>
    <div className="flex flex-col items-center justify-center lg:flex-row lg:items-center mt-2">
      <select
        onChange={(ele) => { console.log('=== ele ===', ele);setCurrentLanguage(ele.target.value) }}
        className=" text-sm bg-transparent border text-black outline-none cursor-pointer"
      >
        {userPackage === 'Free Trial' ? freeTrialLanguages.map((ele) => {
          return <option key={ele.value} className="text-black" value={ele.value}>{ele.name}</option>;
        }) : AllLanguages.map((ele) => {
          return <option key={ele.value} className="text-black" value={ele.value}>{ele.name}</option>;
        })}
      </select>
    </div>
      </div>
    </div>
    
  
  </div>
      
  
  <div class="w-full bg-white mt-12 rounded-lg px-10 py-3 text-center">
  <h1 class="font-bold text-gray-200 text-xl rounded-xl">AI Response</h1>

  <div class="border mt-4">
    <div class="mt-4 h-100 border text-bold text-gray-200 bg-gray-500 text-lg rounded-xl typing-animation">
      {titleLoading ? (
     <ClipLoader color="#00ff00" size={60}  />
      ) : (
      <span className='shadow-lg'>{responseTitle}</span>
      )}
    </div>

    <div class="mt-4 h-100 text-gray-100 bg-gray-500 text-sm px-2 rounded-xl typing-animation" style={{ whiteSpace: 'pre-line' }}>
      {AbstractLoading ? (
     <ClipLoader color="#00ff00" size={60}  />
      ) : (
      <span className='shadow-lg'>{responseAbstract}</span>
      )}
    </div>
  </div>

  <div class="flex flex-col sm:flex-row justify-center sm:justify-between mt-4">
    <button class="font-bold border rounded px-2 mb-2 sm:mb-0 sm:ml-1" type="button" onClick={handleSave}>
      Save
    </button>

    <PDFLoader title={responseTitle} abstract={responseAbstract}
                newsTitle={title} newsLink={url} newImage={multimedia_url}
    
    />
  </div>
</div>

    </div>
  
    
                    
  
  
  </div>

  )
}