import { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import * as ApiUtil from '../Utils/axios';
import * as allUrls from '../Utils/constants';

const EmailVerify = () => {
  const [validUrl, setValidUrl] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const param = useParams();


  useEffect(() => {
    
  const verifyEmailUrl = async () => {
    const backendResponse = await ApiUtil.http(allUrls.URLS.user.verifyEmail, 'POST', { token: param.token });
    if (backendResponse.hasError) {
      setIsLoading(false);
      setValidUrl(false);
    }else {
      setIsLoading(false);
      setValidUrl(true);
    }
    
  }

  verifyEmailUrl();

      
  }, [param]);

  return (
    <>
      {isLoading ?
        <div style={{
          'position': 'fixed',
          'top': '0',
          'left': '0',
          'width': '100%',
          'height': '100%',
          'display': 'flex',
          'justify-content': 'center',
          'align-items': 'center',
           'z-index': '50'
        }}>
          <ClipLoader color="#00ff00" size={150}  />
        </div> : validUrl ? (
        <div style={{
          'position': 'fixed',
          'top': '0',
          'left': '0',
          'width': '100%',
          'height': '100%',
          'display': 'flex',
          'justify-content': 'center',
          'align-items': 'center',
           'z-index': '50'
        }}>
          <img
            src="https://raw.githubusercontent.com/cyber-wolve/AuthInMern/Email-Verify-In-MERN/client/src/images/success.png"
            className="h-[400px] "
            alt=""
          />
          <h1>Email verified successfully</h1>
          <Link to="/signin">
            <button className="bg-purple-500 mt-3 bg-purple-700 w-full dark:bg-purple-500 px-6 py-3 rounded-full dark:hover:bg-purple-600 hover:shadow-lg hover:shadow-purple-500/25">
              Login
            </button>
          </Link>
        </div>
      ) : (
        <div className="flex flex-col justify-center items-center h-screen">
      <div className="text-center">
        <h1 className="text-9xl font-bold text-gray-900 mb-4">404</h1>
        <h2 className="text-4xl font-bold text-gray-900 mb-8">Page not found</h2>
        <button
          className={`bg-purple-800 px-4 py-2 bg-blue-500 rounded-md shadow-md  hover:bg-purple-800 hover:text-white`}
          onClick={() => window.location.href = '/'}
        >
          Go back home
        </button>
      </div>
    </div>
      )}
    </>
  );
};

export default EmailVerify;
