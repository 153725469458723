import { ExclamationCircleIcon } from "@heroicons/react/20/solid";
import { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import { setDataInLocalStorage } from '../Utils/local-storage';
import * as ApiUtil from '../Utils/axios';
import { URLS } from '../Utils/constants';

export default function SignInForm() {
  
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const toastId = toast.loading("Loading ...");

    const response = await ApiUtil.http(URLS.user.signIn, 'POST', formData);
    if (response.hasError) {
      toast.update(toastId, { render: response.errorMessage, type: "error", isLoading: false,  autoClose: 3000  });
    }else {
      setDataInLocalStorage('user', response.data);
      toast.update(toastId, { render: 'Login Successfully', type: "success", isLoading: false,  autoClose: 3000  });
      window.location.href = '/dashboard';
    }

  };


  return (
    <div className="rounded-md bg-content text-xs text-white dark:bg-content bg-center bg-cover p-7 flex items-center flex-col">
      <img className="h-7 mb-5" src="/img/logoWhite.png" alt="logo" />
      <div className="space-y-3 md:space-y-4">
        <h1 className="text-lg text-center font-bold leading-tight tracking-tight md:text-xl">
          Sign in to your account
        </h1>
        <form className="space-y-4 md:space-y-4" onSubmit={handleSubmit}>
          <div>
            <label
              htmlFor="email"
              className="block mb-2  font-medium text-white"
            >
              Your email
            </label>
            <input
              type="email"
              name="email"
              id="email"
              value={formData.email}
              onChange={handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 sm: rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="name@company.com"
              required
            />
          </div>
          <div>
            <label
              htmlFor="password"
              className="block mb-2  font-medium text-white"
            >
              Password
            </label>
            <input
              type="password"
              name="password"
              id="password"
              value={formData.password}
              onChange={handleChange}
              placeholder="••••••••"
              className="bg-gray-50 border border-gray-300 text-gray-900 sm: rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              required
            />
          </div>
          <div className="flex items-center justify-between">
            <div className="flex items-start mt-2">
              <div className="flex items-center h-5">
                <input
                  id="remember"
                  aria-describedby="remember"
                  type="checkbox"
                  className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800"
                  required
                />
              </div>
              <div className="ml-1">
                <label
                  htmlFor="remember"
                  className="text-gray-500 dark:text-gray-300"
                >
                  Remember me
                </label>
              </div>
            </div>
            <Link
              to="/forgetpassword"
              className="text-white font-medium text-primary-600 hover:underline dark:text-primary-500"
            >
              Forgot password?
            </Link>
          </div>
          {/* {responseMsg && (
            <>
              <h1 className="flex justify-center items-center gap-1 text-white bg-red-500 p-2 rounded-md text-center">
                <ExclamationCircleIcon className="h-4 text-white" /> {responseMsg}
              </h1>
            </>
          )} */}
          <button
            type="submit"
            className="bg-purple-500 mt-3 hover:bg-purple-700 w-full dark:bg-purple-500 text-white transition-all px-6 py-3 rounded-full dark:hover:bg-purple-600 hover:shadow-lg hover:shadow-purple-500/25"
          >
            Sign in
          </button>
          <p className="text-sm font-light text-gray-500 dark:text-gray-400">
            Don’t have an account yet ?{" "}
            <Link
              to="/signup/Free Trial/Monthly"
              className="font-medium text-purple-500 hover:underline dark:text-primary-500"
            >
              Sign up
            </Link>
          </p>
        </form>
      </div>
    </div>
  );
}
